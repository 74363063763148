<template>
    <div>
        <SignIn/>
    </div>
</template>


<script>
    import SignIn from '@/components/laptop/Authorization/SignIn.vue';

    export default {
        name: "SignInView",
        components: {
            SignIn
        },
        data() {
            return {

            }
        }
    }
</script>