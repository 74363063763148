<template>
    <AddGoodModalWindowComponent v-if="addGoodIsOpened" v-model:addGoodIsOpened="addGoodIsOpened" v-model:uploadGoodsCSVIsOpened="uploadGoodsCSVIsOpened"/>
    <UpdateGoodModalWindowComponent v-if="updateGoodIsOpened" v-model:updateGoodIsOpened="updateGoodIsOpened" v-model:updatingGood="updatingGood"/>
    <DownloadGoodsCSVModalWindowComponent v-if="downloadGoodsCSVIsOpened" v-model:downloadGoodsCSVIsOpened="downloadGoodsCSVIsOpened"/>
    <UploadGoodsCSVModalWindowComponent v-if="uploadGoodsCSVIsOpened" v-model:uploadGoodsCSVIsOpened="uploadGoodsCSVIsOpened" v-model:addGoodIsOpened="addGoodIsOpened"/>

    <ConfirmationModalWindow v-if="showGoodIsOpened" @confirm="showGood" v-model:isOpened="showGoodIsOpened" title="Вы уверены, что хотите показать эту позицию?"/>
    <ConfirmationModalWindow v-if="hideGoodIsOpened" @confirm="hideGood" v-model:isOpened="hideGoodIsOpened" title="Вы уверены, что хотите скрыть эту позицию?"/>
    <ConfirmationModalWindow v-if="deleteGoodIsOpened" @confirm="deleteGood" v-model:isOpened="deleteGoodIsOpened" title="Вы уверены, что хотите удалить эту позицию?"/>

    <ConfirmationModalWindow v-if="showGoodsIsOpened" @confirm="showGoods" v-model:isOpened="showGoodsIsOpened" title="Вы уверены, что хотите показать выбранные позиции?"/>
    <ConfirmationModalWindow v-if="hideGoodsIsOpened" @confirm="hideGoods" v-model:isOpened="hideGoodsIsOpened" title="Вы уверены, что хотите скрыть выбранные позиции?"/>
    <ConfirmationModalWindow v-if="deleteGoodsIsOpened" @confirm="deleteGoods" v-model:isOpened="deleteGoodsIsOpened" title="Вы уверены, что хотите удалить выбранные позиции?"/>

    <section class="mx-8 px-8 py-6 mb-[10px] bg-white rounded-md flex justify-between items-start">
        <div class="flex items-center justify-between w-full">
            <div class="flex items-center gap-8">
                <button type="button" class="rounded-md bg-indigo-100 px-4 py-2 shadow">Каталог</button>
                <button type="button" @click="goToCharacteristicSettings" class="rounded-md flex items-center">Характеристики</button>
                <button type="button" @click="goToSupplyingAndMoving" class="rounded-md flex items-center">Поставки</button>
            </div>
            <div class="flex items-stretch">
                <button @click="openDownloadGoodsCSV" type="button" class="relative border border-custom-blue hover:bg-gray-100 text-sm text-custom-blue py-2 rounded-md flex items-center font-medium px-5 mr-4 whitespace-nowrap">
                    <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-322.87 268.52-534.35l63.89-65.41L434.5-497.44v-310.69h91v310.69l102.09-102.32 63.89 65.41L480-322.87Zm-237.13 171q-37.78 0-64.39-26.61t-26.61-64.39v-120h91v120h474.26v-120h91v120q0 37.78-26.61 64.39t-64.39 26.61H242.87Z"></path></svg>
                    <span>Выгрузка CSV</span>
                </button>
                <button @click="openAddGood" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                    <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M438-274h86v-162h162v-86H524v-164h-86v164H274v86h164v162Zm42.07 206q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Z"/></svg>
                    <span>Добавить</span>
                </button>
            </div>
        </div>
    </section>

    <section v-if="selectedGoods.length > 0" class="mx-8 px-8 py-6 mb-[10px] bg-white rounded-md flex justify-between items-start">
        <div class="flex items-center justify-between w-full">
            <div class="flex items-center">
                <button @click="openShowGoods" type="button" class="flex items-center rounded-md text-custom-blue border-2 border-custom-blue-dimmed hover:border-custom-blue px-4 py-1 mr-5">
                    <svg class="open-show-button w-5 h-5 cursor-pointer mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                    <span>Показать</span>
                </button>
                <button @click="openHideGoods" type="button" class="flex items-center rounded-md text-custom-blue border-2 border-custom-blue-dimmed hover:border-custom-blue px-4 py-1 mr-5">
                    <svg class="open-hide-button w-5 h-5 cursor-pointer mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
                    <span>Скрыть</span>
                </button>
                <button @click="openDeleteGoods" type="button" class="flex items-center rounded-md text-red-500 border-2 border-red-300 hover:border-red-500 px-4 py-1">
                    <svg class="open-delete-info-button w-5 h-5 cursor-pointer mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                    <span>Удалить</span>
                </button>
            </div>
        </div>
    </section>
    <section v-else class="mx-8 px-8 py-6 mb-[10px] bg-white rounded-md flex justify-between items-start">
        <div class="flex items-stretch justify-between w-full">
            <div class="flex items-center gap-8">
                <select v-model="selectedWarehouse" class="rounded-md p-2 text-sm hover:bg-gray-100 cursor-pointer">
                    <option value="all">Все склады</option>
                    <option value="main">Главный склад</option>
                    <option v-for="kassa in kassas" :key="kassa.id" :value="kassa.id">{{ kassa.name }}</option>
                </select>
                <CheckboxInput v-model:checkboxParam="showHideGoods" labelContent="Отображать скрытые товары"/>
            </div>
            <div class="flex items-stretch">
                <div class="relative w-full mr-4">
                    <input v-model="searchValue" type="search" id="search-dropdown" class="min-w-[25rem] h-full block py-1 px-4 w-full z-20 text-gray-900 rounded-lg border border-custom-blue focus:ring-blue-500 focus:border-blue-500" placeholder="Поиск по наименованию, артикулу и цене..." />
                    <button type="submit" class="absolute top-0 end-0 py-1 px-4 font-medium h-full text-white rounded-e-lg border border-custom-blue bg-custom-blue hover:bg-custom-blue-hover">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                        <span class="sr-only">Search</span>
                    </button>
                </div>
            </div>
        </div>
    </section>
    
    <section class="mx-8 mb-8">
        <div class="relative overflow-visible shadow-md sm:rounded-md">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 select-none">
                <thead class="text-sm text-gray-700 bg-white">
                    <tr class="border-b border-gray-300">
                        <th scope="col" class="px-4 py-3">
                            <CheckboxInput @change="handleChangeAllGoodsSelected" v-model:checkboxParam="allGoodsSelected"/>
                        </th>
                        <th @click="sortByVerboseId" scope="col" class="px-4 py-3 cursor-pointer">
                            <div class="flex items-center justify-between">
                                <span class="font-normal mr-4">Артикул</span>
                                <IconSortAscending v-if="sortedBy == 'verbose-id-ascending'" class="w-4 h-4 shrink-0"/>
                                <IconSortDescending v-if="sortedBy == 'verbose-id-descending'" class="w-4 h-4 shrink-0"/>
                            </div>
                        </th>
                        <th @click="sortByName" scope="col" class="px-4 py-3 cursor-pointer">
                            <div class="flex items-center justify-between">
                                <span class="font-normal mr-4">Наименование</span>
                                <IconSortAscending v-if="sortedBy == 'name-ascending'" class="w-4 h-4 shrink-0"/>
                                <IconSortDescending v-if="sortedBy == 'name-descending'" class="w-4 h-4 shrink-0"/>
                            </div>
                        </th>
                        <th @click="sortByPrice" scope="col" class="px-4 py-3 cursor-pointer">
                            <div class="flex items-center justify-between">
                                <span class="font-normal mr-4">Цена</span>
                                <IconSortAscending v-if="sortedBy == 'price-ascending'" class="w-4 h-4 shrink-0"/>
                                <IconSortDescending v-if="sortedBy == 'price-descending'" class="w-4 h-4 shrink-0"/>
                            </div>
                        </th>
                        <th @click="sortByType" scope="col" class="px-4 py-3 cursor-pointer">
                            <div class="flex items-center justify-between">
                                <span class="font-normal mr-4">Тип</span>
                                <IconSortAscending v-if="sortedBy == 'type-ascending'" class="w-4 h-4 shrink-0"/>
                                <IconSortDescending v-if="sortedBy == 'type-descending'" class="w-4 h-4 shrink-0"/>
                            </div>
                        </th>
                        <th @click="sortByAmount" scope="col" class="px-4 py-3 cursor-pointer">
                            <div class="flex items-center justify-between">
                                <span class="font-normal mr-4">Количество</span>
                                <IconSortAscending v-if="sortedBy == 'amount-ascending'" class="w-4 h-4 shrink-0"/>
                                <IconSortDescending v-if="sortedBy == 'amount-descending'" class="w-4 h-4 shrink-0"/>
                            </div>
                        </th>
                        <th scope="col" class="px-4 py-3 w-[100%]">
                            <span class="font-normal">Характеристики</span>
                        </th>
                        <th scope="col" class="p-3">
                            <span class="sr-only">Дополнительно</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="text-sm">
                    <tr v-if="searchedGoods.length == 0">
                        <td colspan="10" class="text-center text-lg p-2">У вас пока нет товаров</td>
                    </tr>
                    <tr v-for="(good, index) in searchedGoods" :key="good.id" @click="toggleSelectGood($event, good, index)" class="font-normal text-black bg-white border-b cursor-pointer hover:bg-gray-50">
                        <td class="px-4 py-2">
                            <CheckboxInput v-model:checkboxParam="selectionGoods[good.id]"/>
                        </td>
                        <td class="px-4 py-2 whitespace-nowrap">{{ good.verbose_id }}</td>
                        <td class="px-4 py-2 whitespace-nowrap">{{ good.name }}</td>
                        <td class="px-4 py-2 whitespace-nowrap">{{ $globalMethods.formatNumberWithSpaces(good.price) }}</td>
                        <td class="px-4 py-2 whitespace-nowrap">
                            <span>{{ labels.goodFormats[good.format] }}</span>
                            <span v-if="good.format == 'operation' && good.type == 'income'" class="ml-1">(+)</span>
                            <span v-else-if="good.format == 'operation' && good.type == 'expense'" class="ml-1">(-)</span>
                        </td>
                        <td @click.stop="openAmountTooltip(good)" v-if="good.format == 'good'" class="amount-td px-4 py-2 flex items-center justify-center relative">
                            <span v-if="selectedWarehouse != 'all'" class="whitespace-nowrap">{{ $globalMethods.formatNumberWithSpaces(good.amount[selectedWarehouse]|| "0") }}</span>
                            <span v-else class="whitespace-nowrap">{{ $globalMethods.formatNumberWithSpaces(Object.values(good.amount).reduce((acc, value) => acc + value, 0) || "0") }}</span>
                            <svg class="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                            <div v-if="openedAmountGoods[good.id]" class="amount-tooltip absolute rounded bg-white right-0 top-0 translate-x-1/2 -translate-y-full z-[5] p-4 border border-gray-300">
                                <p v-for="(amount, kassa) in getVerboseAmountGood(good)" :key="kassa" class="whitespace-nowrap">{{ `${kassa}: ${amount}` }}</p>
                            </div>
                        </td>
                        <td v-else class="px-4 py-2 text-center">∞</td>
                        <td class="px-4 py-2">{{ $globalMethods.formatCharacteristics(good.characteristics) }}</td>
                        <td class="px-4 py-2 flex items-center gap-2">
                            <svg @click="openHideGood(good)" v-if="good.is_active === true" class="open-hide-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
                            <svg @click="openShowGood(good)" v-else class="open-show-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                            <svg @click="openUpdateGood(good)" class="open-detail-info-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/></svg>
                            <svg @click="openDeleteGood(good)" class="open-delete-info-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>


<script>
    import { mapState } from 'vuex';

    import AddGoodModalWindowComponent from '@/components/laptop/Good/AddGoodModalWindowComponent.vue';
    import UpdateGoodModalWindowComponent from '@/components/laptop/Good/UpdateGoodModalWindowComponent.vue';
    import DownloadGoodsCSVModalWindowComponent from '@/components/laptop/Good/DownloadGoodsCSVModalWindowComponent.vue';
    import UploadGoodsCSVModalWindowComponent from '@/components/laptop/Good/UploadGoodsCSVModalWindowComponent.vue';

    import ConfirmationModalWindow from '@/components/modal/ConfirmationModalWindow.vue';

    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';

    import IconSortAscending from '@/components/Icon/IconSortAscending.vue';
    import IconSortDescending from '@/components/Icon/IconSortDescending.vue';

    export default {
        name: "GoodsListComponent",
        components: {
            AddGoodModalWindowComponent,
            UpdateGoodModalWindowComponent,
            DownloadGoodsCSVModalWindowComponent,
            UploadGoodsCSVModalWindowComponent,

            ConfirmationModalWindow,

            CheckboxInput,

            IconSortAscending,
            IconSortDescending,
        },
        props: {
            mode: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                currentGood: null,
                addGoodIsOpened: false,
                updateGoodIsOpened: false,
                uploadGoodsCSVIsOpened: false,
                deleteGoodIsOpened: false,
                hideGoodIsOpened: false,
                showGoodIsOpened: false,
                deleteGoodsIsOpened: false,
                hideGoodsIsOpened: false,
                showGoodsIsOpened: false,
                downloadGoodsCSVIsOpened: false,
                uploadGoodsCSVModalWindowIsOpened: false,

                updatingGood: null,
                sortedBy: null,

                searchValue: "",
                selectionGoods: {},
                openedAmountGoods: {},
                selectedWarehouse: "all",
                showHideGoods: false,
                allGoodsSelected: false,

                lastCheckedCheckboxIndex: null,
            }
        },
        computed: {
            ...mapState(["goods", "kassas", "labels"]),
            searchedGoods() {
                let result = this.goods.filter(good => good.name.toLowerCase().includes(this.searchValue.toLowerCase()) || good.verbose_id.toLowerCase().includes(this.searchValue.toLowerCase()) || good.price.includes(this.searchValue));
                let hideResult = result.filter(good => !good.is_active);

                result = result.filter(good => good.is_active)
                if (this.selectedWarehouse != "main" && this.selectedWarehouse != "all") {
                    result = result.filter(good => good.shop_ids.includes(this.selectedWarehouse));
                }

                if (this.sortedBy == "verbose-id-ascending") {
                    result.sort((good1, good2) => good1.verbose_id.localeCompare(good2.verbose_id));
                    hideResult.sort((good1, good2) => good1.verbose_id.localeCompare(good2.verbose_id));
                } else if (this.sortedBy == "verbose-id-descending") {
                    result.sort((good1, good2) => good2.verbose_id.localeCompare(good1.verbose_id));
                    hideResult.sort((good1, good2) => good2.verbose_id.localeCompare(good1.verbose_id));
                } else if (this.sortedBy == "name-ascending") {
                    result.sort((good1, good2) => good1.name.localeCompare(good2.name));
                    hideResult.sort((good1, good2) => good1.name.localeCompare(good2.name));
                } else if (this.sortedBy == "name-descending") {
                    result.sort((good1, good2) => good2.name.localeCompare(good1.name));
                    hideResult.sort((good1, good2) => good2.name.localeCompare(good1.name));
                } else if (this.sortedBy == "price-ascending") {
                    result.sort((good1, good2) => good1.price - good2.price);
                    hideResult.sort((good1, good2) => good1.price - good2.price);
                } else if (this.sortedBy == "price-descending") {
                    result.sort((good1, good2) => good2.price - good1.price);
                    hideResult.sort((good1, good2) => good2.price - good1.price);
                } else if (this.sortedBy == "type-ascending") {
                    result.sort((good1, good2) => `${good1.format}${good1.type}`.localeCompare(`${good2.format}${good2.type}`));
                    hideResult.sort((good1, good2) => `${good1.format}${good1.type}`.localeCompare(`${good2.format}${good2.type}`));
                } else if (this.sortedBy == "type-descending") {
                    result.sort((good1, good2) => `${good2.format}${good2.type}`.localeCompare(`${good1.format}${good1.type}`));
                    hideResult.sort((good1, good2) => `${good2.format}${good2.type}`.localeCompare(`${good1.format}${good1.type}`));
                }  else if (this.sortedBy == "amount-ascending") {
                    if (this.selectedWarehouse == "all") {
                        result.sort((good1, good2) => {
                            if (good1.format == "good" && good2.format != "good") { return -1 }
                            if (good1.format != "good" && good2.format == "good") { return 1 }
                            if (good1.format != "good" && good2.format != "good") { return 0}
                            return Object.values(good1.amount || {}).reduce((acc, value) => acc + value, 0) - Object.values(good2.amount || {}).reduce((acc, value) => acc + value, 0)
                        })
                        hideResult.sort((good1, good2) => {
                            if (good1.format == "good" && good2.format != "good") { return -1 }
                            if (good1.format != "good" && good2.format == "good") { return 1 }
                            if (good1.format != "good" && good2.format != "good") { return 0}
                            return Object.values(good1.amount || {}).reduce((acc, value) => acc + value, 0) - Object.values(good2.amount || {}).reduce((acc, value) => acc + value, 0)
                        })
                    } else {
                        result.sort((good1, good2) => {
                            if (good1.format == "good" && good2.format != "good") { return -1 }
                            if (good1.format != "good" && good2.format == "good") { return 1 }
                            if (good1.format != "good" && good2.format != "good") { return 0}
                            if (!good1.amount[this.selectedWarehouse]) { return -1 }
                            if (!good2.amount[this.selectedWarehouse]) { return 1 }
                            return good1.amount[this.selectedWarehouse] - good2.amount[this.selectedWarehouse]
                        });
                        hideResult.sort((good1, good2) => {
                            if (good1.format == "good" && good2.format != "good") { return -1 }
                            if (good1.format != "good" && good2.format == "good") { return 1 }
                            if (good1.format != "good" && good2.format != "good") { return 0}
                            if (!good1.amount[this.selectedWarehouse]) { return -1 }
                            if (!good2.amount[this.selectedWarehouse]) { return 1 }
                            return good1.amount[this.selectedWarehouse] - good2.amount[this.selectedWarehouse]
                        });
                    }
                } else if (this.sortedBy == "amount-descending") {
                    if (this.selectedWarehouse == "all") {
                        result.sort((good1, good2) => {
                            if (good1.format == "good" && good2.format != "good") { return 1 }
                            if (good1.format != "good" && good2.format == "good") { return -1 }
                            if (good1.format != "good" && good2.format != "good") { return 0}
                            return Object.values(good2.amount || {}).reduce((acc, value) => acc + value, 0) - Object.values(good1.amount || {}).reduce((acc, value) => acc + value, 0)
                        })
                        hideResult.sort((good1, good2) => {
                            if (good1.format == "good" && good2.format != "good") { return 1 }
                            if (good1.format != "good" && good2.format == "good") { return -1 }
                            if (good1.format != "good" && good2.format != "good") { return 0}
                            return Object.values(good2.amount || {}).reduce((acc, value) => acc + value, 0) - Object.values(good1.amount || {}).reduce((acc, value) => acc + value, 0)
                        })
                    } else {
                        result.sort((good1, good2) => {
                            if (good1.format == "good" && good2.format != "good") { return 1 }
                            if (good1.format != "good" && good2.format == "good") { return -1 }
                            if (good1.format != "good" && good2.format != "good") { return 0}
                            if (!good1.amount[this.selectedWarehouse]) { return 1 }
                            if (!good2.amount[this.selectedWarehouse]) { return -1 }
                            return good2.amount[this.selectedWarehouse] - good1.amount[this.selectedWarehouse]
                        });
                        hideResult.sort((good1, good2) => {
                            if (good1.format == "good" && good2.format != "good") { return 1 }
                            if (good1.format != "good" && good2.format == "good") { return -1 }
                            if (good1.format != "good" && good2.format != "good") { return 0}
                            if (!good1.amount[this.selectedWarehouse]) { return 1 }
                            if (!good2.amount[this.selectedWarehouse]) { return -1 }
                            return good2.amount[this.selectedWarehouse] - good1.amount[this.selectedWarehouse]
                        });
                    }
                } else {
                    result.sort((good1, good2) => good2.id - good1.id)
                    hideResult.sort((good1, good2) => good2.id - good1.id)
                }
                if (this.showHideGoods) {
                    result = result.concat(hideResult);
                }
                return result;
            },
            selectedGoods() {
                let result = [];
                for (const goodId in this.selectionGoods) {
                    if (this.selectionGoods[goodId]) {
                        result.push(Number(goodId));
                    }
                }
                return result;
            }, 
        },
        methods: {
            validateDeleteGood() {
                if (this.currentGood.format != "good") {
                    return true;
                }

                for (const key in this.currentGood.amount) {
                    if (this.currentGood.amount[key] != 0) {
                        this.$notify("Вы не можете удалить товар с ненулевым остатком. Вам необходимо сначала списать товарный остаток из всех складов", "red", 5000);
                        return false;
                    }
                }
                return true;
            },
            validateDeletingGoods() {
                for (const goodId of this.selectedGoods) {
                    const good = this.goods.find(good => good.id == goodId);
                    if (good.format != "good") {
                        continue;
                    }
                    for (const key in good.amount) {
                        if (good.amount[key] != 0) {
                            this.$notify("Вы не можете удалить товары с ненулевым остатком. Вам необходимо сначала списать товарные остатки из всех складов", "red", 5000);
                            return false;
                        }
                    }
                }
                return true;
            },
            async deleteGoods() {
                if (!this.validateDeletingGoods()) {
                    this.closeDeleteGoods();
                    return;
                }
                const result = await this.$store.dispatch("deleteGoods", this.selectedGoods);
                if (result.successfully) {
                    this.$notify("Выбранные позиции успешно удалены");
                    this.closeDeleteGoods();
                }
            },
            async hideGoods() {
                const result = await this.$store.dispatch("hideGoods", this.selectedGoods);
                if (result.successfully) {
                    this.$notify("Выбранные позиции успешно добавлены в скрытые");
                    this.closeHideGoods();
                }
            },
            async showGoods() {
                const result = await this.$store.dispatch("showGoods", this.selectedGoods);
                if (result.successfully) {
                    this.$notify("Выбранные позиции успешно удалены из скрытых");
                    this.closeShowGoods();
                }
            },
            async deleteGood() {
                if (!this.validateDeleteGood()) {
                    this.closeDeleteGood();
                    return;
                }
                const result = await this.$store.dispatch("deleteGood", this.currentGood.id);
                if (result.successfully) {
                    this.$notify("Позиция успешно удалена");
                    this.closeDeleteGood();
                }
            },
            async hideGood() {
                const result = await this.$store.dispatch("hideGood", this.currentGood.id);
                if (result.successfully) {
                    this.$notify("Позиция успешно добавлена в скрытые");
                    this.closeHideGood();
                }
            },
            async showGood() {
                const result = await this.$store.dispatch("showGood", this.currentGood.id);
                if (result.successfully) {
                    this.$notify("Позиция успешно удалена из скрытых");
                    this.closeShowGood();
                }
            },
            goToSupplyingAndMoving() {
                this.$emit("update:mode", "supplying-and-moving");
            },
            goToCharacteristicSettings() {
                this.$emit("update:mode", "characteristics");
            },
            openDownloadGoodsCSV() {
                document.body.classList.add("overflow-hidden");
                this.downloadGoodsCSVIsOpened = true;
            },
            openUpdateGood(good) {
                document.body.classList.add("overflow-hidden");
                this.updatingGood = good;
                this.updateGoodIsOpened = true;
            },
            openAddGood() {
                document.body.classList.add("overflow-hidden");
                this.addGoodIsOpened = true;
            },
            openShowGoods() {
                document.body.classList.add("overflow-hidden");
                this.showGoodsIsOpened = true;
            },
            closeShowGoods() {
                this.autoFillSelectionGoods();
                document.body.classList.remove("overflow-hidden");
                this.showGoodsIsOpened = false;
            },
            openHideGoods() {
                document.body.classList.add("overflow-hidden");
                this.hideGoodsIsOpened = true;
            },  
            closeHideGoods() {
                this.autoFillSelectionGoods();
                document.body.classList.remove("overflow-hidden");
                this.hideGoodsIsOpened = false;
            },
            openDeleteGoods() {
                document.body.classList.add("overflow-hidden");
                this.deleteGoodsIsOpened = true;
            },
            closeDeleteGoods() {
                this.autoFillSelectionGoods();
                document.body.classList.remove("overflow-hidden");
                this.deleteGoodsIsOpened = false;
            },
            openHideGood(good) {
                document.body.classList.add("overflow-hidden");
                this.currentGood = this.goods.find(element => element.id == good.id)
                this.hideGoodIsOpened = true;
            },
            closeHideGood() {
                document.body.classList.remove("overflow-hidden");
                this.currentGood = null;
                this.hideGoodIsOpened = false;
            },
            openShowGood(good) {
                document.body.classList.add("overflow-hidden");
                this.currentGood = this.goods.find(element => element.id == good.id)
                this.showGoodIsOpened = true;
            },
            closeShowGood() {
                document.body.classList.remove("overflow-hidden");
                this.currentGood = null;
                this.showGoodIsOpened = false;
            },
            openDeleteGood(good) {
                document.body.classList.add("overflow-hidden");
                this.currentGood = this.goods.find(element => element.id == good.id)
                this.deleteGoodIsOpened = true;
            },
            closeDeleteGood() {
                document.body.classList.remove("overflow-hidden");
                this.currentGood = null;
                this.deleteGoodIsOpened = false;
            },
            sortByName() {
                if (this.sortedBy == "name-ascending") {
                    this.sortedBy = "name-descending";
                } else if (this.sortedBy == "name-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "name-ascending";
                }
            },
            sortByPrice() {
                if (this.sortedBy == "price-ascending") {
                    this.sortedBy = "price-descending";
                } else if (this.sortedBy == "price-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "price-ascending";
                }
            },
            sortByType() {
                if (this.sortedBy == "type-ascending") {
                    this.sortedBy = "type-descending";
                } else if (this.sortedBy == "type-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "type-ascending";
                }
            },
            sortByAmount() {
                if (this.sortedBy == "amount-ascending") {
                    this.sortedBy = "amount-descending";
                } else if (this.sortedBy == "amount-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "amount-ascending";
                }
            },
            sortByVerboseId() {
                if (this.sortedBy == "verbose-id-ascending") {
                    this.sortedBy = "verbose-id-descending";
                } else if (this.sortedBy == "verbose-id-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "verbose-id-ascending";
                }
            },
            handleChangeAllGoodsSelected() {
                for (const good of this.searchedGoods) {
                    this.selectionGoods[good.id] = this.allGoodsSelected;
                }
            },
            toggleSelectGood(event, good, index) {
                if (event.target.closest(".open-hide-button")) {
                    return;
                }
                if (event.target.closest(".open-show-button")) {
                    return;
                }
                if (event.target.closest(".open-detail-info-button")) {
                    return;
                }
                if (event.target.closest(".open-delete-info-button")) {
                    return;
                }
                this.selectionGoods[good.id] = !this.selectionGoods[good.id];

                if (this.lastCheckedCheckboxIndex !== null && this.lastCheckedCheckboxIndex !== undefined) {
                    if (event.shiftKey) {
                        const start = Math.min(this.lastCheckedCheckboxIndex, index);
                        const end = Math.max(this.lastCheckedCheckboxIndex, index);
                        for (let i = start; i <= end; i++) {
                            const currentGood = this.searchedGoods[i];
                            this.selectionGoods[currentGood.id] = this.selectionGoods[good.id];
                        }
                    }
                }
                this.lastCheckedCheckboxIndex = index;
            },
            autoFillSelectionGoods() {
                this.selectionGoods = {};
                for (const good of this.goods) {
                    this.selectionGoods[good.id] = false;
                }
                this.allGoodsSelected = false;
            },
            getVerboseAmountGood(good) {
                if (good.format != "good") {
                    return;
                }
                const verboseAmount = {"Главный склад": good.amount["main"] || 0}
                for (const kassaId of good.shop_ids) {
                    const kassa = this.kassas.find(kassa => kassa.id == kassaId);
                    if (kassa) {
                        verboseAmount[kassa.name] = good.amount[kassaId] || 0;
                    }
                }
                return verboseAmount;
            },
            openAmountTooltip(good) {
                for (const currentGood of this.searchedGoods) {
                    if (currentGood.id != good.id) {
                        this.openedAmountGoods[currentGood.id] = false;
                    }
                }
                this.openedAmountGoods[good.id] = !this.openedAmountGoods[good.id];
            },
            addAutoCloseAmountTooltips() {
                document.body.addEventListener("click", (event) => {
                    if (event.target.closest(".amount-td")) {
                        return
                    }
                    for (const good of this.searchedGoods) {
                        this.openedAmountGoods[good.id] = false;
                    }
                })
            },
        },
        mounted() {
            this.autoFillSelectionGoods();
            this.addAutoCloseAmountTooltips();
        },
    }
</script>