<template>
    <div v-if="catalogWindowIsOpened" class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[80vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeCatalogWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl text-center mb-8">Каталог</p>
                    <div class="flex items-center justify-between mb-4">
                        <div class="relative w-[30rem]">
                            <input v-model="valueToSearchingCatalog" id="catalog-search" class="min-w-[25rem] block py-1 pl-4 pr-14 w-full z-20 text-gray-900 rounded-lg border border-custom-blue focus:ring-blue-500 focus:border-blue-500" placeholder="Поиск по наименованию, артикулу и комментарию" required />
                            <button type="button" class="absolute top-0 end-0 py-1 px-4 font-medium h-full text-white rounded-e-lg border border-custom-blue bg-custom-blue hover:bg-custom-blue-hover">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                                <span class="sr-only">Search</span>
                            </button>
                        </div>
                        <button @click="addSelectedGoodsFromCatalog" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Добавить</button>
                    </div>
                    <div>
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 select-none">
                            <thead class="text-sm text-gray-700 bg-white">
                                <tr class="border-b border-gray-300">
                                    <th scope="col" class="p-2 w-[5%]">
                                        <CheckboxInput v-if="forWindow != 'kassaOperationList'" @change="handleChangeAllGoodsSelected" ref="selectAllGoodInCatalog" v-model:checkboxParam="allGoodsSelected"/>
                                        <RadioInput v-else v-model:radioParam="selectedGood" @change="selectGoodToFilter(null, '')" name="good" :value="null" labelContent=""/>
                                    </th>
                                    <th @click="sortByVerboseId" scope="col" class="cursor-pointer px-4 py-3 w-[10%]">
                                        <div class="flex items-center justify-between">
                                            <span class="font-normal mr-4">Артикул</span>
                                            <IconSortAscending v-if="sortedBy == 'verbose-id-ascending'" class="w-4 h-4 shrink-0"/>
                                            <IconSortDescending v-if="sortedBy == 'verbose-id-descending'" class="w-4 h-4 shrink-0"/>
                                        </div>
                                    </th>
                                    <th @click="sortByName" scope="col" class="cursor-pointer px-4 py-3 w-[20%]">
                                        <div class="flex items-center justify-between">
                                            <span class="font-normal mr-4">Наименование</span>
                                            <IconSortAscending v-if="sortedBy == 'name-ascending'" class="w-4 h-4 shrink-0"/>
                                            <IconSortDescending v-if="sortedBy == 'name-descending'" class="w-4 h-4 shrink-0"/>
                                        </div>
                                    </th>
                                    <th @click="sortByPrice" scope="col" class="cursor-pointer px-4 py-3 w-[20%]">
                                        <div class="flex items-center justify-between">
                                            <span class="font-normal mr-4">Цена</span>
                                            <IconSortAscending v-if="sortedBy == 'price-ascending'" class="w-4 h-4 shrink-0"/>
                                            <IconSortDescending v-if="sortedBy == 'price-descending'" class="w-4 h-4 shrink-0"/>
                                        </div>
                                    </th>
                                    <th @click="sortByAmount" scope="col" class="cursor-pointer px-4 py-3 w-[15%]">
                                        <div class="flex items-center justify-between">
                                            <span class="font-normal mr-4">Количество</span>
                                            <IconSortAscending v-if="sortedBy == 'amount-ascending'" class="w-4 h-4 shrink-0"/>
                                            <IconSortDescending v-if="sortedBy == 'amount-descending'" class="w-4 h-4 shrink-0"/>
                                        </div>
                                    </th>
                                    <th scope="col" class="px-4 py-3 w-0">
                                        <span class="font-normal">Характеристики</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <td v-if="searchedGoodsInCatalog.length == 0" colspan="6" class="text-base text-center pt-4">Не найдено ни одной позиции в каталоге</td>
                                <tr v-else v-for="(good, index) in searchedGoodsInCatalog" @click="selectGoodInCatalog($event, good, index)" :key="good.id" class="border-b border-gray-300 font-normal text-black bg-white cursor-pointer">
                                    <td class="p-2">
                                        <CheckboxInput v-if="forWindow != 'kassaOperationList'" v-model:checkboxParam="good.selected"/>
                                        <RadioInput v-else v-model:radioParam="selectedGood" @change="selectGoodToFilter(good.id, good.name)" name="good" :value="good.id" labelContent=""/>
                                    </td>
                                    <td class="px-4 py-2 whitespace-nowrap">
                                        <span>{{ good.verbose_id }}</span>
                                    </td>
                                    <td class="px-4 py-2">
                                        <span>{{ good.name }}</span>
                                    </td>
                                    <td class="px-4 py-2">
                                        <span>{{ $globalMethods.formatNumberWithSpaces(good.price) }}</span>
                                    </td>
                                    <td @click.stop="openAmountTooltip(good)" v-if="good.format == 'good'" class="amount-td px-4 py-2 flex items-center justify-center relative">
                                        <span v-if="currentKassaId != 'all'" class="whitespace-nowrap">{{ $globalMethods.formatNumberWithSpaces(good.amount[currentKassaId]|| "0") }}</span>
                                        <span v-else class="whitespace-nowrap">{{ $globalMethods.formatNumberWithSpaces(Object.values(good.amount).reduce((acc, value) => acc + value, 0) || "0") }}</span>
                                        <svg class="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                                        <div v-if="openedAmountGoods[good.id]" class="amount-tooltip absolute rounded bg-white right-0 top-0 translate-x-1/2 -translate-y-full z-[5] p-4 border border-gray-300">
                                            <p v-for="(amount, kassa) in getVerboseAmountGood(good)" :key="kassa" class="whitespace-nowrap">{{ `${kassa}: ${amount}` }}</p>
                                        </div>
                                    </td>
                                    <td v-else class="px-4 py-2 text-center">∞</td>
                                    <td class="px-4 py-2 whitespace-nowrap overflow-x-scroll no-scrollbar">
                                        <span>{{ $globalMethods.formatCharacteristics(good.characteristics) }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Decimal from 'decimal.js';
    import { mapState } from 'vuex';

    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';
    import RadioInput from '@/components/InputComponents/RadioInput.vue';
    import IconSortAscending from '@/components/Icon/IconSortAscending.vue';
    import IconSortDescending from '@/components/Icon/IconSortDescending.vue';

    export default {
        name: "CatalogWindowComponent",
        components: {
            CheckboxInput,
            RadioInput,
            IconSortAscending,
            IconSortDescending,
        },
        props: {
            catalogWindowIsOpened: {
                type: Boolean,
                required: true,
            },
            selectedGoodParam: {
                type: [Number, String, null],
                required: false,
            },
            searchedGoodParam: {
                type: [Number, String, null],
                required: false,
            },
            operations: {
                type: Array,
                required: true,
            },
            initialCatalogGoods: {
                type: Array,
                required: true,
            },
            currentKassaId: {
                type: [String, Number],
                required: false,
            },
            toKassaId: {
                type: [String, Number],
                required: false,
            },
            forWindow: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                catalogGoods: [],
                valueToSearchingCatalog: "",
                allGoodsSelected: false,
                sortedBy: null,
                lastCheckedCheckboxIndex: null,
                openedAmountGoods: {},
                selectedGood: null,
                searchedGood: null,
            }
        },
        computed: {
            ...mapState(["companyData", "kassas"]),
            searchedGoodsInCatalog() {
                let result = this.catalogGoods.filter(good => (good.name.toLowerCase().includes(this.valueToSearchingCatalog.toLowerCase()) || good.verbose_id.toLowerCase().includes(this.valueToSearchingCatalog.toLowerCase())))
                if (this.sortedBy == "verbose-id-ascending") {
                    result.sort((good1, good2) => good1.verbose_id.localeCompare(good2.verbose_id));
                } else if (this.sortedBy == "verbose-id-descending") {
                    result.sort((good1, good2) => good2.verbose_id.localeCompare(good1.verbose_id));
                } else if (this.sortedBy == "name-ascending") {
                    result.sort((good1, good2) => good1.name.localeCompare(good2.name));
                } else if (this.sortedBy == "name-descending") {
                    result.sort((good1, good2) => good2.name.localeCompare(good1.name));
                } else if (this.sortedBy == "price-ascending") {
                    result.sort((good1, good2) => Number(good1.price) - Number(good2.price));
                } else if (this.sortedBy == "price-descending") {
                    result.sort((good1, good2) => Number(good2.price) - Number(good1.price));
                } else if (this.sortedBy == "amount-ascending") {
                    result.sort((good1, good2) => {
                        if (good1.format == "good" && good2.format != "good") { return -1 }
                        if (good1.format != "good" && good2.format == "good") { return 1 }
                        if (good1.format != "good" && good2.format != "good") { return 0 }
                        if (!good1.amount[this.currentKassaId]) { return -1 }
                        if (!good2.amount[this.currentKassaId]) { return 1 }
                        return Number(good1.amount[this.currentKassaId]) - Number(good2.amount[this.currentKassaId])
                    });
                } else if (this.sortedBy == "amount-descending") {
                    result.sort((good1, good2) => {
                        if (good1.format == "good" && good2.format != "good") { return 1 }
                        if (good1.format != "good" && good2.format == "good") { return -1 }
                        if (good1.format != "good" && good2.format != "good") { return 0 }
                        if (!good1.amount[this.currentKassaId]) { return 1 }
                        if (!good2.amount[this.currentKassaId]) { return -1 }
                        return Number(good2.amount[this.currentKassaId]) - Number(good1.amount[this.currentKassaId])
                    })
                } else {
                    result.sort((good1, good2) => good1.name.localeCompare(good2.name));
                }

                return result;
            },

        },
        methods: {
            getVerboseAmountGood(good) {
                if (good.format != "good") {
                    return;
                }
                const verboseAmount = {"Главный склад": good.amount["main"] || 0}
                for (const kassaId of good.shop_ids) {
                    const kassa = this.kassas.find(kassa => kassa.id == kassaId);
                    if (kassa) {
                        verboseAmount[kassa.name] = good.amount[kassaId] || 0;
                    }
                }
                return verboseAmount;
            },
            openAmountTooltip(good) {
                for (const currentGood of this.searchedGoodsInCatalog) {
                    if (currentGood.id != good.id) {
                        this.openedAmountGoods[currentGood.id] = false;
                    }
                }
                this.openedAmountGoods[good.id] = !this.openedAmountGoods[good.id];
            },
            addAutoCloseAmountTooltips() {
                document.body.addEventListener("click", (event) => {
                    if (event.target.closest(".amount-td")) {
                        return
                    }
                    for (const good of this.searchedGoodsInCatalog) {
                        this.openedAmountGoods[good.id] = false;
                    }
                })
            },
            sortByVerboseId() {
                if (this.sortedBy == "verbose-id-ascending") {
                    this.sortedBy = "verbose-id-descending";
                } else if (this.sortedBy == "verbose-id-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "verbose-id-ascending";
                }
            },
            sortByName() {
                if (this.sortedBy == "name-ascending") {
                    this.sortedBy = "name-descending";
                } else if (this.sortedBy == "name-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "name-ascending";
                }
            },
            sortByPrice() {
                if (this.sortedBy == "price-ascending") {
                    this.sortedBy = "price-descending";
                } else if (this.sortedBy == "price-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "price-ascending";
                }
            },
            sortByAmount() {
                if (this.sortedBy == "amount-ascending") {
                    this.sortedBy = "amount-descending";
                } else if (this.sortedBy == "amount-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "amount-ascending";
                }
            },
            copyInitialCatalogGoods() {
                this.catalogGoods = structuredClone(this.initialCatalogGoods);
            },
            closeCatalogWindow() {
                this.$emit('update:catalogWindowIsOpened', false);
            },
            selectGoodInCatalog(event, good, index) {
                if (this.forWindow == "kassaOperationList") {
                    this.selectGoodToFilter(good.id, good.name)
                    return;
                }
                good.selected = !good.selected;

                if (this.lastCheckedCheckboxIndex !== null && this.lastCheckedCheckboxIndex !== undefined) {
                    if (event.shiftKey) {
                        const start = Math.min(this.lastCheckedCheckboxIndex, index);
                        const end = Math.max(this.lastCheckedCheckboxIndex, index);
                        for (let i = start; i <= end; i++) {
                            const currentGood = this.searchedGoodsInCatalog[i];
                            currentGood.selected = good.selected;
                        }
                    }
                }
                this.lastCheckedCheckboxIndex = index;
            },
            handleChangeAllGoodsSelected() {
                for (const element of this.catalogGoods) {
                    element.selected = this.allGoodsSelected;
                }
            },
            filterAndUniqueOperations(operations) {
                let result = operations.filter(operation => operation.good);
                const uniqueGoods = new Set();
                result = result.filter((operation) => {
                    if (uniqueGoods.has(operation.good.id)) {
                        return false;
                    }
                    uniqueGoods.add(operation.good.id);
                    return true;
                })
                return result;
            },
            selectGoodToFilter(goodId, goodName) {
                this.selectedGood = goodId;
                this.searchedGood = goodName;
            },
            addSelectedGoodsFromCatalog() {
                if (this.forWindow == "kassaOperationList") {
                    this.$emit("update:selectedGoodParam", this.selectedGood);
                    this.$emit("update:searchedGoodParam", this.searchedGood);
                    this.closeCatalogWindow();
                    return
                }

                const selectedGoods = this.catalogGoods.filter(element => element.selected === true);
                if (selectedGoods.length == 0) {
                    const selectAllGoodInCatalog = this.$refs.selectAllGoodInCatalog.$refs.checkboxInput;
                    selectAllGoodInCatalog.setCustomValidity("Выберите минимум одну позицию для добавления");
                    selectAllGoodInCatalog.reportValidity();
                    return
                }

                if (this.forWindow == "supplying") {
                    let operations = [];
                    for (const good of selectedGoods) {
                        operations.push({
                            good: good,
                            amount: 0,
                            resultAmount: good.amount[this.currentKassaId] || 0,
                        })
                    }
                    operations = this.operations.concat(operations);
                    operations = this.filterAndUniqueOperations(operations);
                    this.$emit("update:operations", operations);
                    this.closeCatalogWindow();
                    return;
                }

                if (this.forWindow == "moving") {
                    let operations = [];
                    for (const good of selectedGoods) {
                        operations.push({
                            good: good,
                            amount: 0,
                            resultAmountFrom: good.amount[this.currentKassaId] || 0,
                            resultAmountTo: good.amount[this.toKassaId] || 0,
                        })
                    }
                    operations = this.operations.concat(operations);
                    operations = this.filterAndUniqueOperations(operations);
                    this.$emit("update:operations", operations);
                    this.closeCatalogWindow();
                    return;
                }

                if (this.forWindow == "sale") {
                    let operations = [];
                    for (const good of selectedGoods) {
                        let amount;
                        if (good.maxAmount == 0 && good.format == 'good') {
                            amount = 0
                        } else {
                            amount = 1
                        }

                        let maxAmount;
                        if (good.amount) {
                            maxAmount = good.amount[this.currentKassaId] || 0;
                        } else {
                            maxAmount = 0;
                        }

                        operations.push({
                            good: good,
                            good_verbose_id: good.verbose_id,
                            id: good.id,
                            name: good.name,
                            format: good.format,
                            price: Number(good.price),
                            price_without_discount: Number(good.price),
                            amount: amount,
                            maxAmount: maxAmount,
                            total_price: Number(new Decimal(good.price).mul(amount).toString()),   
                        })
                    }
                    operations = this.operations.concat(operations);
                    operations = this.filterAndUniqueOperations(operations);
                    this.$emit("update:operations", operations);
                    this.closeCatalogWindow();
                    return;
                } 

                if (this.forWindow == "operation") {
                    let operations = [];
                    for (const good of selectedGoods) {
                        operations.push({
                            good: good,
                            good_verbose_id: good.verbose_id,
                            id: good.id,
                            name: good.name,
                            price: Number(good.price),
                            amount: 1,
                            total_price:  Number(new Decimal(good.price).toString()),
                        })
                    }
                    operations = this.operations.concat(operations);
                    operations = this.filterAndUniqueOperations(operations);
                    this.$emit("update:operations", operations);
                    this.closeCatalogWindow();
                    return;
                }
            },
        },
        created() {
            this.copyInitialCatalogGoods();
        },
        mounted() {
            if (this.selectedGoodParam) {
                this.selectedGood = this.selectedGoodParam;
            }
            this.addAutoCloseAmountTooltips();
        },
    }
</script>