<template>
    <ConfirmationModalWindow v-if="hideWindowIsOpened" @confirm="hideStaff" v-model:isOpened="hideWindowIsOpened" title="Скрытые сотрудники остаются в системе, но не могут выполнять операции или просматривать данные в вашей компании. Вы уверены, что хотите скрыть сотрудника?"/>
    <ConfirmationModalWindow v-if="activateWindowIsOpened" @confirm="activateStaff" v-model:isOpened="activateWindowIsOpened" title="Активные сотрудники снова получают доступ к выполнению операцию и просмотру данных в вашей компании. Вы уверены, что хотите сделать сотрудника активным?"/>
    <ConfirmationModalWindow v-if="deleteWindowIsOpened" @confirm="deleteStaff" v-model:isOpened="deleteWindowIsOpened" title="Удаленные сотрудники стираются из вашей базы данных и теряют доступ к выполнению операций и просмотру данных в вашей компании. Чтобы заново предоставить доступ для этого сотрудника, вам будет необходимо снова добавить его. Если не уверены в выборе, вы можете временно отключить сотрудника от компании, скрыв его без удаления. Вы уверены, что хотите удалить сотрудника?"/>
    <AddStaffWindow v-if="addStaffModalWindowIsOpened" v-model:windowIsOpened="addStaffModalWindowIsOpened"/>
    <UpdateStaffWindow v-if="updateStaffModelWindowIsOpened" v-model:windowIsOpened="updateStaffModelWindowIsOpened" :staff="currentStaff"/>

    <section class="mx-8 px-8 py-6 mb-[10px] bg-white rounded-md flex justify-between items-start">
        <div class="flex justify-between w-full">
            <span class="text-3xl">Сотрудники</span>
            <button @click="openAddStaffModalWindow" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M438-274h86v-162h162v-86H524v-164h-86v164H274v86h164v162Zm42.07 206q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Z"/></svg>
                <span>Добавить</span>
            </button>
        </div>
    </section>
    <section class="mx-8 mb-8">
        <div class="relative overflow-x-auto shadow-md sm:rounded-md">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead class="text-sm text-gray-700 bg-white">
                    <tr class="border-b border-gray-300">
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">ФИО</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">Кассы</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">Телефон</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-auto">
                            <span class="font-normal">Email</span>
                        </th>
                        <th scope="col" class="p-3 w-0">
                            <span class="sr-only">Дополнительно</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="text-sm">
                    <tr v-if="sortedStaffs.length == 0">
                        <td colspan="10" class="text-center text-lg p-2">У вас пока нет cотрудников</td>
                    </tr>
                    <tr v-for="staff in sortedStaffs" :key="staff.id" class="font-normal text-black bg-white border-b cursor-pointer hover:bg-gray-50">
                        <td class="px-4 py-2 whitespace-nowrap overflow-scroll no-scrollbar">{{ staff.name }}</td>
                        <td class="px-4 py-2 whitespace-nowrap overflow-scroll no-scrollbar">{{ getVerboseNameOfKassas(staff) }}</td>
                        <td class="px-4 py-2 whitespace-nowrap overflow-scroll no-scrollbar">{{ staff.phone }}</td>
                        <td class="px-4 py-2 whitespace-nowrap overflow-scroll no-scrollbar">{{ staff.email }}</td>
                        <td class="px-4 py-2 flex items-center gap-2">
                            <svg v-if="staff.is_active" @click="openHideModalWindow(staff)" class="open-hide-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
                            <svg v-else @click="openActivateModalWindow(staff)" class="open-show-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                            <svg @click="openUpdateStaffModalWindow(staff)" class="open-detail-info-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/></svg>
                            <svg @click="openDeleteModalWindow(staff)" class="open-delete-info-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>


<script>
    import { mapState } from 'vuex';

    import ConfirmationModalWindow from '@/components/modal/ConfirmationModalWindow.vue';
    import AddStaffWindow from '@/components/laptop/Staff/AddStaffWindow.vue';
    import UpdateStaffWindow from '@/components/laptop/Staff/UpdateStaffWindow.vue';

    export default {
        name: "StaffPage",
        components: {
            ConfirmationModalWindow,
            AddStaffWindow,
            UpdateStaffWindow,
        },
        computed: {
            ...mapState(["kassas", "staffs", "labels"]),
            sortedStaffs() {
                return structuredClone(this.staffs).sort((staff1, staff2) => {return staff2.is_active - staff1.is_active})
            }
        },
        data() {
            return {
                hideWindowIsOpened: false,
                activateWindowIsOpened: false,
                deleteWindowIsOpened: false,
                addStaffModalWindowIsOpened: false,
                updateStaffModelWindowIsOpened: false,

                currentStaff: null,
            }
        },
        methods: {
            async hideStaff() {
                const result = await this.$store.dispatch("updateStaffActivateStatus", {
                    staffId: this.currentStaff.id, 
                    is_active: false,
                });
                if (result.successfully) {
                    this.$notify("Сотрудник успешно добавлен в скрытые");
                    this.closeHideModalWindow();
                }
            },
            async activateStaff() {
                const result = await this.$store.dispatch("updateStaffActivateStatus", {
                    staffId: this.currentStaff.id, 
                    is_active: true,
                });
                if (result.successfully) {
                    this.$notify("Сотрудник успешно удален из скрытых");
                    this.closeActivateModalWindow();
                }
            },
            async deleteStaff() {
                const result = await this.$store.dispatch("deleteStaff", this.currentStaff.id);
                if (result.successfully) {
                    this.$notify("Сотрудник успешно удален");
                    this.closeDeleteModalWindow();
                }
            },
            openDeleteModalWindow(staff) {
                document.body.classList.add("overflow-hidden");
                this.deleteWindowIsOpened = true;
                this.currentStaff = staff;
            },
            closeDeleteModalWindow() {
                document.body.classList.remove("overflow-hidden");
                this.deleteWindowIsOpened = false;
                this.currentStaff = null;
            },
            openActivateModalWindow(staff) {
                document.body.classList.add("overflow-hidden");
                this.activateWindowIsOpened = true;
                this.currentStaff = staff;
            },
            closeActivateModalWindow() {
                document.body.classList.remove("overflow-hidden");
                this.activateWindowIsOpened = false;
                this.currentStaff = null;
            },
            openHideModalWindow(staff) {
                document.body.classList.add("overflow-hidden");
                this.hideWindowIsOpened = true;
                this.currentStaff = staff;
            },
            closeHideModalWindow() {
                document.body.classList.remove("overflow-hidden");
                this.hideWindowIsOpened = false;
                this.currentStaff = null;
            },
            openUpdateStaffModalWindow(staff) {
                document.body.classList.add("overflow-hidden");
                this.currentStaff = staff;
                this.updateStaffModelWindowIsOpened = true;
            },
            openAddStaffModalWindow() {
                document.body.classList.add("overflow-hidden");
                this.addStaffModalWindowIsOpened = true;
            },
            getVerboseNameOfKassas(staff) {
                let result = [];
                for (const shopId of staff.shops) {
                    const shop = this.kassas.find(kassa => kassa.id == shopId);
                    result.push(shop.name);
                }
                result = result.join(" | ");
                if (result.length > 45) {
                    result = `${result.slice(0, 55)} ...`;
                }
                return result;
            }
        }
    }
</script>