<template>
    <CatalogWindowComponent v-if="catalogIsOpened" v-model:catalogWindowIsOpened="catalogIsOpened" v-model:operations="positions" :initialCatalogGoods="onlyGoodsInWarehouse" :currentKassaId="warehouse" forWindow="supplying" />
    <div>
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-20"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-30">
            <div class="relative flex flex-col justify-between bg-white z-30 max-h-[95vh] min-h-[80vh] w-[45vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeAddSupplyingModalWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div class="flex flex-col items-center justify-between flex-1">
                    <div>
                        <ModalWindowLabel content="Новая поставка" class="mb-8"/>
                        <KassaSelection @change="handleChangingOfWarehouse" v-model:kassaParam="warehouse" labelContent="Склад" :withMainWarehouse="true" class="w-1/2 mb-4"/>
                        <div>
                            <div class="grid grid-cols-12 gap-2 mb-2">
                                <label class="block col-span-5 text-sm text-gray-500">Товар</label>
                                <label class="block col-span-3 text-sm text-gray-500">Количество</label>
                                <label class="block col-span-4 text-sm text-gray-500">Итог</label>
                            </div>
                            <div v-for="(position, index) in positions" :key="position" class="relative grid grid-cols-12 gap-2 mb-2">
                                <div class="text-sm text-gray-500 flex items-center justify-center absolute top-1/2 -translate-y-1/2 left-[-2rem]">{{ index + 1 }}</div>
                                <GoodSelection :ref="`goodSelection-${index}`" @change="updateResultAmount(position)" @openCatalogWindow="openCatalogWindow" v-model:goodParam="position.good" :goodChoices="onlyGoodsInWarehouseToSelect" :currentKassaId="warehouse" class="col-span-5"/>
                                <AmountInput :ref="`amount-input-${index}`" @input="handleAmountInput(index, position)" v-model:amountParam="position.amount" class="col-span-3"/>
                                <AmountInput v-model:amountParam="position.resultAmount" :readOnly="true" class="col-span-4"/>
                                <div v-if="positions.length > 1" class="absolute top-1/2 -translate-y-1/2 right-[-2rem] flex items-center justify-center">
                                    <IconClose @click="deletePosition(index)" class="w-5 h-5 cursor-pointer"/>
                                </div>
                            </div>
                        </div>
                        <ButtonWithPlus @click="addPosition" buttonContent="Добавить товар" class="mb-4"/>
                        <CommentInput v-model:commentParam="note" labelContent="Комментарий" class="w-full mb-8"/>
                    </div>
                    <button @click="createNewSupplying" :disabled="buttonIsDisabled" type="button" class="w-full text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import ModalWindowLabel from '@/components/Label/ModalWindowLabel.vue';
    import CatalogWindowComponent from '@/components/laptop/Good/CatalogWindowComponent.vue';

    import GoodSelection from '@/components/InputComponents/GoodSelection.vue';
    import AmountInput from '@/components/InputComponents/AmountInput.vue';
    import CommentInput from '@/components/InputComponents/CommentInput.vue';
    import KassaSelection from '@/components/InputComponents/KassaSelection.vue';

    import ButtonWithPlus from '@/components/Button/ButtonWithPlus.vue';

    import IconClose from '@/components/Icon/IconClose.vue';

    export default {
        name: "AddSupplyingModalWindow",
        components: {
            ModalWindowLabel,
            CatalogWindowComponent,
            GoodSelection,
            AmountInput,
            CommentInput,
            KassaSelection,
            ButtonWithPlus,
            IconClose,
        },
        props: {
            addSupplyingModalWindowIsOpened: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                kassaSelectionIsOpened: false,
                catalogIsOpened: false,

                warehouse: null,
                positions: [{good: null, amount: 0, resultAmount: 0}],
                note: "",

                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["kassas", "goods"]),
            onlyGoodsInWarehouse() {
                let warehouseGoods;
                if (this.warehouse == "main") {
                    warehouseGoods = this.goods.filter(good => good.format == "good");
                } else {
                    warehouseGoods = this.goods.filter(good => good.format == "good" && good.shop_ids.includes(this.warehouse));
                }
                return warehouseGoods;
            },
            onlyGoodsInWarehouseToSelect() {
                let warehouseGoods = this.onlyGoodsInWarehouse;
                let usedGoods = this.positions.map(position => position.good ? position.good.id : undefined);
                let result = warehouseGoods.filter(good => !usedGoods.includes(good.id));
                return result;
            },
        },
        methods: {
            validateCreatingData() {
                for (const [index, position] of this.positions.entries()) {
                    if (position.good == null) {
                        const goodSelectionInput = this.$refs[`goodSelection-${index}`][0].$refs.goodSelectionInput;
                        goodSelectionInput.setCustomValidity("Поле товара обязательно для заполнения");
                        goodSelectionInput.reportValidity();
                        return false;
                    }
                    if (!position.amount || Number(position.amount) == 0) {
                        const amountInput = this.$refs[`amount-input-${index}`][0].$refs.amountInput;
                        amountInput.setCustomValidity("Количество должно быть больше нуля");
                        amountInput.reportValidity();
                        return false;
                    }
                }
                return true;
            },
            async createNewSupplying() {
                if (!this.validateCreatingData()) {
                    return
                }
                this.buttonIsDisabled = true;

                let goods = {}
                for (const position of this.positions) {
                    goods[position.good.id] = Number(position.amount);
                }
                const result = await this.$store.dispatch("createNewSupplying", {
                    description: this.note,
                    warehouse_id: this.warehouse,
                    goods_data: goods,
                })
                if (result.successfully) {
                    this.$notify("Поставка успешно добавлена");
                    this.closeAddSupplyingModalWindow();
                }
                this.buttonIsDisabled = false;
            },
            handleAmountInput(index, position) {
                const amountInput = this.$refs[`amount-input-${index}`][0].$refs.amountInput;
                amountInput.setCustomValidity("");
                this.updateResultAmount(position);
            },
            updateResultAmount(position) {
                if (position.good == null) {
                    return;
                }
                position.resultAmount = Number(position.amount || 0) + Number(position.good.amount[this.warehouse] || 0);
            },
            openCatalogWindow() {
                this.catalogIsOpened = true;
            },
            handleChangingOfWarehouse() {
                this.positions = [{good: null, amount: 0, resultAmount: 0}];
            },
            deletePosition(index) {
                this.positions.splice(index, 1);
            },
            addPosition() {
                this.positions.push({name: null, amount: 0, resultAmount: 0});
            },
            closeAddSupplyingModalWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:addSupplyingModalWindowIsOpened", false);
            },
        },
        mounted() {

        },
    }
</script>