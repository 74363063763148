<template>
    <div ref="goodSelectionBlock" class="relative">  
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>      
        <input v-model="searchValueParam" @focus="openGoodSelection" @input="handleInputGoodSelection" @keydown.enter="handleEnterPress" ref="goodSelectionInput" :id="uniqueId" type="text" class="w-full min-h-[2.375rem] text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-3">
        <div v-if="goodSelectionIsOpened" ref="goodSelection" class="mt-2 z-10 bg-white absolute rounded-lg shadow border">
            <div class="p-3">
                <button @click="openCatalogWindow" type="button" class="w-full text-center bg-custom-blue hover:bg-custom-blue-hover text-white font-medium rounded-lg text-sm px-5 py-2">Открыть каталог</button>
            </div>
            <div class="max-h-52 px-3 pb-3 overflow-scroll text-sm text-gray-700">
                <div v-if="filteredGoodChoices.length == 0" class="whitespace-nowrap">По вашему запросу ничего не найдено</div>
                <RadioInput v-for="good in filteredGoodChoices" :key="good.id" @change="handleChangeGoodSelection(good)" v-model:radioParam="selectedGood" name="good" :value="good.id">
                    <div class="flex items-center gap-2">
                        <span class="whitespace-nowrap text-xs text-gray-500">{{ good.verbose_id }}</span>
                        <span class="whitespace-nowrap">{{ good.name }}</span>
                        <span class="whitespace-nowrap text-xs text-gray-500">{{ good.format != "good" ? "Количество: ∞": currentKassaId ? `Количество: ${good.amount[currentKassaId] || "0"}` : `Количество: ${good.amount[kassaData.id] || "0"}` }}</span>
                        <div class="ml-2 flex flex-col gap-1 text-xs text-gray-500">
                            <span v-for="[name, value] in Object.entries(good.characteristics).filter(([k, v]) => v !== '' && v !== null && v !== undefined)" :key="name" class="whitespace-nowrap">{{ `${name}: ${value}` }}</span>
                        </div>
                    </div>
                </RadioInput>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';
    import { v4 as uuidv4 } from 'uuid';

    import RadioInput from './RadioInput.vue';


    export default {
        name: "GoodSelection",
        components: {
            RadioInput,
        },
        props: {
            goodParam: {
                type: Object,
                required: false,
            },
            goodChoices: {
                type: Array,
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            },
            currentKassaId: {
                type: [String, Number],
                required: false,
            }
        },
        computed: {
            ...mapState(["kassaData"]),
            filteredGoodChoices() {
                const result = this.goodChoices.filter(good => good.name.toLowerCase().includes(this.searchValue.toLowerCase()) || good.verbose_id.toLowerCase().includes(this.searchValue.toLowerCase()));
                result.sort((good1, good2) => good1.name.localeCompare(good2.name));
                return result;
            },
            searchValueParam: {
                get() {
                    return this.goodParam ? this.goodParam.name : this.searchValue
                },
                set(value) {
                    this.$emit("update:goodParam", undefined);
                    this.searchValue = value;
                }
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
                goodSelectionIsOpened: false,
                searchValue: "",
                catalogWindowIsOpened: false,
                selectedGood: null,
            }
        },
        methods: {
            handleEnterPress() {
                const firstGood = this.filteredGoodChoices[0];
                if (firstGood) {
                    this.handleChangeGoodSelection(firstGood);
                }
            },
            handleChangeGoodSelection(good) {
                this.$emit("update:goodParam", structuredClone(good));
                this.$emit("change");
                this.goodSelectionIsOpened = false;
                this.searchValue = good.name;
            },
            openCatalogWindow() {
                this.$emit("openCatalogWindow");
            },
            handleInputGoodSelection() {
                this.$refs.goodSelectionInput.setCustomValidity("");
                this.openGoodSelection();
            },
            openGoodSelection() {
                this.goodSelectionIsOpened = true;
            },
            autCloseGoodSelectionBlock() {
                const goodSelectionBlock = this.$refs.goodSelectionBlock;
                document.body.addEventListener("click", (event) => {
                    if (goodSelectionBlock.contains(event.target)) {
                        return;
                    }
                    this.goodSelectionIsOpened = false;
                })
            },
        },
        mounted() {
            this.autCloseGoodSelectionBlock();
        }
    }
</script>