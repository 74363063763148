<template>
    <MainHeaderComponent activePage="account"/>
    <PersonalAccount/>
</template>

<script>
    import MainHeaderComponent from '@/components/laptop/MainHeaderComponent.vue';
    import PersonalAccount from '@/components/laptop/Authorization/PersonalAccount.vue';

    export default {
        name: "PersonalAccountView",
        components: {
            MainHeaderComponent,
            PersonalAccount
        },
        data() {
            return {

            }
        },
        mounted() {

        },
    }
</script>