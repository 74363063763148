<template>
    <div>
        <label v-if="labelContent" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
        <div class="grid grid-cols-2 gap-4">
            <RadioInput @change="handleChangingOperationType" v-model:radioParam="operationTypeValue" name="operation-type" value="income" labelContent="Доход"/>
            <RadioInput @change="handleChangingOperationType" v-model:radioParam="operationTypeValue" name="operation-type" value="expense" labelContent="Расход"/>
        </div>
    </div>
</template>


<script>
    import RadioInput from './RadioInput.vue';


    export default {
        name: "OperationTypeSelection",
        components: {
            RadioInput,
        },
        props: {
            operationTypeParam: {
                type: String,
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            }
        },
        computed: {
            operationTypeValue: {
                get() {
                    return this.operationTypeParam;
                },
                set(value) {
                    this.$emit("update:operationTypeParam", value);
                }
            }
        },
        data() {
            return {

            }
        },
        methods: {
            handleChangingOperationType() {
                this.$emit("change");
            }
        }
    }
</script>