<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[45vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">Перевод</p>
                    <div class="grid grid-cols-4 gap-4 mb-8">
                        <PaymentMethodSelection paymentMethodParam="cash" labelContent="Метод" :readOnly="true" class="col-span-2"/>
                        <KassaSelection ref="kassaSelection" v-model:kassaParam="transferTo" labelContent="Куда" :withMainWarehouse="false" :withoutKassas="[kassaData.id]" class="col-span-2"/>
                        <PriceInput ref="transferToPriceInput" v-model:priceParam="price" :priceCurrency="currency" labelContent="Сумма" class="col-span-2"/>
                        <div class="col-span-2">
                            <label class="mb-1 block text-sm text-gray-500">Валюта</label>
                            <select v-model="currency" class="ring-0 shadow-none border outline-0 focus:ring-0 focus:shadow-none focus:outline-0 w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center !py-2 !px-4">
                                <option v-for="currentCurrency in currencies" :key="currentCurrency" :value="currentCurrency">{{ currentCurrency }}</option>
                            </select>
                        </div>
                        <CommentInput v-model:commentParam="note" labelContent="Комментарий" class="col-span-4"/>
                    </div>
                </div>
                <button @click.stop="createNewTransferSale" ref="createNewTransferSaleButton" :disabled="buttonIsDisabled" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';
    import PaymentMethodSelection from '@/components/InputComponents/PaymentMethodSelection.vue';
    import KassaSelection from '@/components/InputComponents/KassaSelection.vue';
    import CommentInput from '@/components/InputComponents/CommentInput.vue';
    import PriceInput from '@/components/InputComponents/PriceInput.vue';

    export default {
        name: "AddTransferWindowComponent",
        components: {
            PaymentMethodSelection,
            KassaSelection,
            CommentInput,
            PriceInput,
        },
        props: {
            addTransferWindowIsOpened: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                transactionMethodIsOpened: false,
                transferToIsOpened: false,

                currency: null,
                price: null,
                transactionMethod: "cash",
                note: "",
                transferTo: null,

                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["labels", "currencies", "kassaData", "kassas"]),
            availableKassas() {
                return this.kassas.filter(kassa => kassa.id != this.kassaData.id && kassa.is_freeze == false);
            }
        },
        methods: {
            validateDataToCreateTransfer() {
                if (!this.transferTo) {
                    const kassaSelection = this.$refs.kassaSelection;
                    kassaSelection.$refs.kassaSelectionBlock.scrollIntoView({ behavior: "smooth", block: "start" });
                    kassaSelection.$refs.kassaSelectionButton.click();
                    return false;
                }
                if (!this.price) {
                    const priceInput = this.$refs.transferToPriceInput.$refs.priceInput;
                    priceInput.setCustomValidity("Поле суммы обязательное");
                    priceInput.reportValidity();
                    return false;
                }

                if (this.price > Number(this.kassaData.cash_in_cash_register[this.currency])) {
                    const priceInput = this.$refs.transferToPriceInput.$refs.priceInput;
                    priceInput.setCustomValidity("Сумма не может превышать текущего количество наличных в кассе");
                    priceInput.reportValidity();
                    return false;
                }

                return true;
            },
            async createNewTransferSale() {
                if (!this.validateDataToCreateTransfer()) {
                    return;
                }
                this.buttonIsDisabled = true;
                const result = await this.$store.dispatch("createNewTransferSale", {
                    transactionMethod: this.transactionMethod,
                    transfer_to: this.transferTo,
                    price: -this.price,
                    currency: this.currency,
                    note: this.note,
                })

                if (result.successfully) {
                    this.$notify("Перевод успешно выполнен");
                    this.closeWindow();
                }
                this.buttonIsDisabled = false;
            },
            createInitData() {
                this.currency = this.kassaData.default_currency;
            },
            closeWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:addTransferWindowIsOpened", false);
            },
        },
        created() {
            this.createInitData();
        },
        mounted() {

        }
    }
</script>