<template>
    <div class="min-w-screen min-h-screen flex items-center justify-center">
        <div class="w-[600px] h-auto bg-white rounded-lg px-[4rem] py-[3rem]">
            <div class="grid grid-cols-2 gap-4">
                <p class="col-span-2 text-2xl mb-4">Регистрация в системе</p>
                <TextInput v-model:textParam="username" labelContent="Email" class="col-span-2"/>
                <TextInput v-model:textParam="name" labelContent="ФИО" class=""/>
                <PhoneInputComponent v-model:inputParam="phone" labelContent="Номер телефона" placeholder="Номер с кодом страны" class=""/>
                <PasswordInput v-model:passwordParam="password" labelContent="Пароль" class=""/>
                <PasswordInput v-model:passwordParam="passwordConfirmation" labelContent="Подтверждение пароля" class=""/>
                <label class="col-span-2 flex items-top gap-2 mb-4">
                    <input type="checkbox" class="cursor-pointer rounded mt-[2px]">
                    <p class="block text-sm">Я ознакомился и принимаю <router-link class="text-blue-600">пользовательское соглашение</router-link></p>
                </label>
                <button @click="executeCaptcha">Проверка reCAPTCHA</button>
                <button type="button" class="col-span-2 w-full bg-custom-blue hover:bg-custom-blue-hover text-center text-white font-medium rounded-lg text-sm px-5 py-2 mb-4">Создать аккаунт</button>
                <div class="col-span-2 flex items-center flex-col gap-1 text-sm">
                    <p>Уже есть аккаунт?</p>
                    <router-link :to="{'name': 'sign-in'}" class="text-blue-600 cursor-pointer">Войти в аккаунт</router-link>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import TextInput from '@/components/InputComponents/TextInput.vue';
    import PasswordInput from '@/components/InputComponents/PasswordInput.vue';
    import PhoneInputComponent from '@/components/InputComponents/PhoneInputComponent.vue';

    export default {
        name: "SignIn",
        components: {
            TextInput,
            PasswordInput,
            PhoneInputComponent,
        },
        computed: {

        },
        data() {
            return {                
                name: null,
                username: null,
                password: null,
                passwordConfirmation: null,
                phone: null,
            }
        },
        methods: {
            async executeCaptcha() {
                try {
                    const token = await this.$recaptcha("sign-up");
                    console.log("reCAPTCHA Token:", token);

                    // Отправка токена на сервер для валидации
                    // await this.verifyCaptchaOnServer(token);
                } catch (error) {
                    this.$notify("Упс! что-то пошло не так...", "red");
                    console.error(error.stack);
                }
            },
        },
    }
</script>