<template>
    <div>
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[35vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeUploadGoodsCSVWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div class="flex items-center mb-6">
                    <svg @click="closeUploadGoodsCSVWindowAndOpenAddGoodWindow" class="w-5 h-5 mr-4 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg>
                    <p class="text-xl">Загрузить через CSV</p>
                </div>
                <div class="mb-6">
                    <p class="text-sm mb-2">Скачайте наш шаблон</p>
                    <div class="flex items-center justify-between gap-4">
                        <button @click="downloadGoodsTemplate('windows')" type="button" class="w-full flex items-center justify-between bg-custom-light-blue border border-custom-light-blue hover:bg-custom-light-blue-hover text-custom-blue font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                            <span>Windows</span>
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>
                        </button>
                        <button @click="downloadGoodsTemplate('macos')" type="button" class="w-full flex items-center justify-between bg-custom-light-blue border border-custom-light-blue hover:bg-custom-light-blue-hover text-custom-blue font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                            <span>MacOS и Linux</span>
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>
                        </button>
                    </div>
                </div>
                <div class="mb-6">
                    <ul class="list-disc list-inside text-sm">
                        <li>Обязательные поля: <b>Наименование</b>, <b>Тип</b> и <b>Магазины</b>.</li>
                        <li>Поле <b>Артикул</b> должно быть <b>уникальным</b> и состоять <b>не более чем из 6 символов</b></li>
                    </ul>
                </div>
                <KassaMultiSelection ref="kassaSelectionBlock" v-model:multiSelectionParam="selectedKassas" class="mb-6"/>
                <div class="mb-12">
                    <label for="upload-goods-csv-file" type="button" class="mb-2 flex items-center justify-center rounded-md text-custom-blue border-2 border-custom-blue-dimmed hover:border-custom-blue px-4 py-1 cursor-pointer">
                        <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>
                        <span>Загрузить CSV файл</span>
                    </label>
                    <p v-if="errorMessage.length > 0" class="text-sm text-red-500 text-center">{{ errorMessage }}</p>
                    <p v-if="statusMessage.length > 0" class="text-sm text-blue-500 text-center">{{ statusMessage }}</p>
                    <input @input="validateCSVFile($event)" ref="uploadGoodsCSVFileInput" id="upload-goods-csv-file" type="file" accept="text/csv" class="w-0 h-0 hidden">
                    <div v-if="runValidationProcessIcon" class="flex items-center justify-center">
                        <svg aria-hidden="true" class="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                    </div>
                </div>
                <button @click.stop="uploadCSVFile" :disabled="uploadCSVFileIsDisabled" ref="sendRequestToUploadGoodsButton" :class="uploadCSVFileIsDisabled ? 'cursor-not-allowed' : ''" type="button" class="mt-4 text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import KassaMultiSelection from '@/components/InputComponents/KassaMultiSelection.vue';

    export default {
        name: "UploadGoodsCSVModalWindowComponent",
        components: {
            KassaMultiSelection,
        },
        props: {
            uploadGoodsCSVIsOpened: {
                type: Boolean,
                required: true,
            },
            addGoodIsOpened: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                errorMessage: "",
                statusMessage: "",
                runValidationProcessIcon: false,

                selectedKassas: {},
                file: null,
                uploadCSVFileIsDisabled: true,

                errorVerboseMessages: {
                    "The file is empty": "Ваш файл пустой",
                    "Invalid csv headers": "Неверные заголовки CSV-файла. Пожалуйста, скачайте актуальный шаблон CSV",
                    "Invalid csv body": "Неверный контент CSV-файла. Проверьте содержимое файла",
                    "Too many data": "Слишком много данных. За раз можно загрузить не более 100 строк в CSV",

                    "Length of vendor code must be less than 6 symbols": "Длина Артикула превышает 6 символов",
                    "Vendor code can contain only digit, alphabet symbols": "Артикул может содержать только цифры и буквы",
                    "Vendor code can't be '000000'": "Артикул не может быть равен '000000'",
                    "The good with the vendor code already exist": "Позиция с таким артикулом уже есть в вашем каталоге",
                    "Good name is required": "Не указано наименование",
                    'Price must be number type': "Цена должна быть числом",
                    "Type must be one of ['товар', 'услуга', 'операция (+)', 'операция (-)']": "Тип должен быть одним из следующих значений: Товар, Услуга, Операция (+), Операция (-)",
                    "Characteristic can't contain '=' and ';'": "Характеристики не могут содержать символы '=' и ';'",
                    "Vendor codes must be unique": "Атрикулы дублируются",

                    "The phones must be unique": "Номера телефонов клиентов дублируются",
                    "The emails must be unique": "Email клиентов дублируются",
                    "Name is required": "Не указано имя клиента",
                    "Invalid total payments value": "Неверное значение суммы покупок. Пожалуйста, убедитесь, что вы вводите только числа (в качестве разделителя дробной части необходимо использовать точку)",
                    "Invalid discount value": "Значение скидки должно быть числом в диапазоне от 0 до 100 не включительно",
                    "Phone number is required": "Поле номера телефона обязательное у каждого клиента",
                    "Invalid phone number": "Неверный формат номера телефона",
                },
            }
        },
        computed: {
            ...mapState(["kassas"]),
        },
        methods: {
            async uploadCSVFile() {
                this.runValidationProcessIcon = false;
                if (!this.file) {
                    return;
                }
                if (this.uploadCSVFileIsDisabled) {
                    return;
                }
                if (Object.values(this.selectedKassas).every(value => !value)) {
                    const kassaMultiSelectionComponent = this.$refs.kassaSelectionBlock;
                    kassaMultiSelectionComponent.isOpened = true;
                    return;
                }

                this.statusMessage = "Данные в процессе загрузки";
                this.runValidationProcessIcon = true;
                const result = await this.$store.dispatch("uploadCSVGoods", {file:this.file, shopsData: this.selectedKassas});
                if (result.successfully) {
                    await this.$store.dispatch("fetchGoods");
                    this.statusMessage = "Данные успешно загружены";
                    this.uploadCSVFileIsDisabled = true;
                    this.runValidationProcessIcon = false;
                    this.file = null;
                    setTimeout(() => {
                        this.closeUploadGoodsCSVWindow();
                    }, 1000);
                } else {
                    this.file = null;
                    this.uploadCSVFileIsDisabled = true;
                    const uploadGoodsCSVFileInput = this.$refs.uploadGoodsCSVFileInput;
                    uploadGoodsCSVFileInput.value = "";
                    if (result.detail) {
                        const verboseError = this.errorVerboseMessages[result.detail];
                        if (verboseError) {
                            this.errorMessage = verboseError;
                        }
                    } else {
                        this.errorMessage = "Неожиданная ошибка. Попробуйте снова через некоторое время или обратитесь в поддержку";
                    }
                }
            },
            async validateCSVFile(event) {
                this.errorMessage = "";
                this.statusMessage = "";
                this.runValidationProcessIcon = false;
                this.uploadCSVFileIsDisabled = true;

                this.file = event.target.files[0];
                if (!this.file) {
                    return;
                }

                if (this.file.size / (1024 * 1024) > 3) {
                    this.errorMessage = "Размер файла не может превышать 3Мб";
                    this.file = null;
                    event.target.value = "";
                    return;
                }

                this.runValidationProcessIcon = true;
                const result = await this.$store.dispatch("validateCSVGoods", this.file);
                this.runValidationProcessIcon = false;
                if (result.successfully) {
                    this.statusMessage = "Файл корректный";
                    this.uploadCSVFileIsDisabled = false;
                } else {
                    this.file = null;
                    event.target.value = "";
                    if (result.detail) {
                        const verboseError = this.errorVerboseMessages[result.detail];
                        if (verboseError) {
                            this.errorMessage = verboseError;
                        }
                    } else {
                        this.errorMessage = "Неожиданная ошибка. Попробуйте снова через некоторое время или обратитесь в поддержку";
                    }
                }
            },
            downloadGoodsTemplate(os) {
                this.$store.dispatch("fetchCSVGoodsTemplate", os)
            }, 
            closeUploadGoodsCSVWindowAndOpenAddGoodWindow() {
                this.$emit("update:addGoodIsOpened", true);
                this.$emit("update:uploadGoodsCSVIsOpened", false);
            },
            closeUploadGoodsCSVWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:uploadGoodsCSVIsOpened", false);
            },
            initData() {
                for (const kassa of this.kassas) {
                    this.selectedKassas[kassa.id] = false;
                }
            },
        },
        mounted() {
            this.initData();
        },
    }
</script>