<template>
    <UpdateKassaWindowComponent v-if="updateKassaWindowIsOpened" v-model:updateKassaWindowIsOpened="updateKassaWindowIsOpened" :updatingKassaID="updatingKassaID" />
    <AddKassaWindowComponent v-if="addKassaWindowIsOpened" v-model:addKassaWindowIsOpened="addKassaWindowIsOpened" />
    <DownloadTransactionCSVModalWindowComponent v-if="downloadTransactionsCSVWindowIsOpened" v-model:downloadTransactionsCSVWindowIsOpened="downloadTransactionsCSVWindowIsOpened" :downloadingKassaID="downloadingKassaID"/>

    <section class="mx-8 px-8 py-6 mb-[5px] bg-white rounded-md flex justify-between items-start">
        <div class="flex flex-col justify-start">
            <h2 class="text-3xl flex items-center">
                <span class="mr-2">Кассы</span>  
            </h2>
        </div>
        <div class="flex items-stretch">
            <button @click="openAddKassaWindow" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="1.5rem" width="1.5rem" viewBox="0 -960 960 960" fill="currentColor"><path d="M438-274h86v-162h162v-86H524v-164h-86v164H274v86h164v162Zm42.07 206q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Z"/></svg>
                <span>Добавить</span>
            </button>
        </div>
    </section>
    <section class="mx-8 px-8 py-6 mb-8 bg-white rounded-md grid grid-cols-3 gap-8">
        <div @click="openKassa($event, kassa.id)" v-for="kassa in kassas" :key="kassa.id" class="relative rounded p-2 border border-custom-blue-dimmed/[0.3] bg-custom-blue-dimmed/[0.2]">
            <div class="flex items-center justify-between w-full border-b border-gray-300 p-4">
                <div class="flex flex-col items-start">
                    <p class="text-xl mb-2">{{ kassa.name }}</p>
                    <p class="text-sm">{{ kassa.address }}</p>
                </div>
                <svg @click="openUpdateKassaWindow(kassa.id)" :ref="`editKassa-${kassa.id}`" class="w-6 h-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M202.63-202.87h57.24l374.74-374.74-56.76-57-375.22 375.22v56.52Zm-90.76 91v-185.3l527.52-526.76q12.48-11.72 27.7-17.96 15.21-6.24 31.93-6.24 16.48 0 32.2 6.24 15.71 6.24 27.67 18.72l65.28 65.56q12.48 11.72 18.34 27.56 5.86 15.83 5.86 31.79 0 16.72-5.86 32.05-5.86 15.34-18.34 27.82L297.65-111.87H111.87Zm642.87-586.39-56.24-56.48 56.24 56.48Zm-148.89 92.41-28-28.76 56.76 57-28.76-28.24Z"/></svg>
            </div>
            <div class="flex items-center p-4 text-gray-600 border-b border-gray-300">
                <svg class="w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="currentColor"><g><rect fill="none" height="24" width="24"></rect><path d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-2 0H3V6h14v8zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm13 0v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"></path></g></svg>
                <div clas="text-sm">
                    <span class="mr-2">Наличных в кассе:</span>
                    <span class="mr-1">{{ $globalMethods.formatNumberWithSpaces(kassa.cash_in_cash_register[companyData.main_currency]) }}</span>
                    <span>{{ labels.currencies[companyData.main_currency] }}</span>
                </div>
            </div>
            <div class="flex items-center justify-between p-4">
                <div @click="openDownloadTransactionsCSVWindow(kassa.id)" :ref="`uploadTransactionsCSV-${kassa.id}`" class="flex items-center justify-center gap-2 cursor-pointer">
                    <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-322.87 268.52-534.35l63.89-65.41L434.5-497.44v-310.69h91v310.69l102.09-102.32 63.89 65.41L480-322.87Zm-237.13 171q-37.78 0-64.39-26.61t-26.61-64.39v-120h91v120h474.26v-120h91v120q0 37.78-26.61 64.39t-64.39 26.61H242.87Z"/></svg>
                    <p class="text-sm font-semibold">Выгрузка CSV</p>
                </div>
                <div class="flex items-center justify-center gap-2 cursor-pointer">
                    <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"></path><rect height="1.5" width="5" x="6.25" y="7.72"></rect><rect height="1.5" width="5" x="13" y="15.75"></rect><rect height="1.5" width="5" x="13" y="13.25"></rect><polygon points="8,18 9.5,18 9.5,16 11.5,16 11.5,14.5 9.5,14.5 9.5,12.5 8,12.5 8,14.5 6,14.5 6,16 8,16"></polygon><polygon points="14.09,10.95 15.5,9.54 16.91,10.95 17.97,9.89 16.56,8.47 17.97,7.06 16.91,6 15.5,7.41 14.09,6 13.03,7.06 14.44,8.47 13.03,9.89"></polygon></g></svg>
                    <p class="text-sm font-semibold">Перейти в кассу</p>
                </div>
            </div>
        </div>
        <div v-if="kassas.length == 0" class="text-lg">У вас пока нет касс</div>
    </section>
</template>


<script>
    import { mapState } from 'vuex';

    import UpdateKassaWindowComponent from '@/components/laptop/Kassa/UpdateKassaWindowComponent.vue';
    import AddKassaWindowComponent from "@/components/laptop/Kassa/AddKassaWindowComponent.vue"
    import DownloadTransactionCSVModalWindowComponent from '@/components/laptop/Kassa/DownloadTransactionCSVModalWindowComponent.vue';

    export default {
        name: "KassaListComponent",
        components: {
            UpdateKassaWindowComponent,
            AddKassaWindowComponent,
            DownloadTransactionCSVModalWindowComponent,
        },
        props: {

        },
        data() {
            return {
                updateKassaWindowIsOpened: false,
                addKassaWindowIsOpened: false,
                downloadTransactionsCSVWindowIsOpened: false,

                updatingKassaID: null,
                downloadingKassaID: null,
            }
        },
        computed: {
            ...mapState(["kassas", "companyData", "currencies", "labels"]),
        },
        methods: {
            openDownloadTransactionsCSVWindow(kassaID) {
                document.body.classList.add("overflow-hidden");
                this.downloadingKassaID = kassaID;
                this.downloadTransactionsCSVWindowIsOpened = true;
            },
            openAddKassaWindow() {
                document.body.classList.add("overflow-hidden");
                this.addKassaWindowIsOpened = true;
            },
            openUpdateKassaWindow(kassaId) {
                document.body.classList.add("overflow-hidden");
                this.updatingKassaID = kassaId;
                this.updateKassaWindowIsOpened = true;
            },
            openKassa(event, kassaID) {
                const editKassaButton = this.$refs[`editKassa-${kassaID}`][0];
                const uploadTransactionsCSVButton = this.$refs[`uploadTransactionsCSV-${kassaID}`][0];
                if (editKassaButton.contains(event.target)) {
                    return;
                }
                if (uploadTransactionsCSVButton.contains(event.target)) {
                    return;
                }
                this.$globalMethods.setDataToLocalStorage("currentKassaID", kassaID);
                this.$router.push({ name: 'kassa' });
            },
        },
        mounted() {
            this.$globalMethods.deleteDataFromLocalStorage("currentKassaID", null);
        },
        beforeUnmount() {
 
        },
    }
</script>