<template>
    <div>
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
        <input v-model="phoneParam" :id="uniqueId" ref="phoneInput" type="text" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" :placeholder="placeholder">
    </div>
</template>


<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "PhoneInputComponent",
        props: {
            inputParam: {
                type: [String, Number, null],
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            },
            placeholder: {
                type: String,
                default: "Введите номер телефона с кодом страны"
            }
        },
        computed: {
            phoneParam: {
                get() {
                    return this.$globalMethods.formatPhoneNumber(this.inputParam);
                },
                set(value) {
                    console.log(this.$globalMethods.keepOnlyNumbers(value));
                    this.$emit("update:inputParam", this.$globalMethods.keepOnlyNumbers(value));
                    console.log(this.inputParam);
                },
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
            }
        },
        methods: {

        },
        mounted() {

        },
        beforeUnmount() {
            
        }
    }
</script>