import Decimal from 'decimal.js'; 

const phoneMasks = {
    "+7": { country: "RU", mask: "## (###) ###-##-##" },
    "+1": { country: "US", mask: "## (###) ###-####" },
    "+375": { country: "BY", mask: "#### (##) ###-##-##" },
    "+998": { country: "UZ", mask: "#### (##) ###-##-##" },
    "+86": { country: "CN", mask: "### (###) #### ####" },
    "+994": { country: "AZ", mask: "#### ## ### ## ##" },
    "+90": { country: "TR", mask: "### (###) ### ## ##" },
    "+995": { country: "GE", mask: "#### (###) ## ## ##" },
    "+66": { country: "TH", mask: "### ## ### ####" }
};

export default {
    getAbsoluteUrl(url) {
        return `${process.env.BASE_URL}${url}`;
    },
    formatDate(languageCode, dateString) {
        const date = new Date(dateString);
        
        if (languageCode == "en") {
            const formattedEn = `${new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date)} ${date.getDate()} ${date.getFullYear()} at ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`;
            return formattedEn;
        } else if (languageCode == "ru") {
            const optionsRu = { year: 'numeric', month: 'long', day: 'numeric' };
            const formattedRu = `${date.toLocaleDateString('ru-RU', optionsRu)}`;
            return formattedRu;
        }
    },
    formatDateTime(dateString) {
        const date = new Date(dateString);

        const formatter = new Intl.DateTimeFormat('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });

        const parts = formatter.formatToParts(date);

        const components = {};
        for (const part of parts) {
            components[part.type] = part.value;
        }

        const hours = components.hour.padStart(2, '0');
        const minutes = components.minute.padStart(2, '0');
        const day = components.day;
        const month = components.month;
        const year = components.year;

        return `${hours}:${minutes} ${day} ${month} ${year} г`;
    },
    convertUTCToTimeZoneISO(dateString, timeZone) {
        const date = new Date(dateString);
        const options = { timeZone, timeZoneName: 'short' };
    
        const formatter = new Intl.DateTimeFormat('en-US', {
            ...options,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    
        const parts = formatter.formatToParts(date);
        const dateComponents = Object.fromEntries(parts.map(part => [part.type, part.value]));
    
        const [month, day, year, hour, minute, second] = [
            dateComponents.month,
            dateComponents.day,
            dateComponents.year,
            dateComponents.hour,
            dateComponents.minute,
            dateComponents.second,
        ];
    
        return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    },
    dropTimezoneFromDateTime(dateString) {
        const date = new Date(dateString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    isDifferenceWithinOneYear(date1, date2) {
        const [earlierDate, laterDate] = date1 < date2 ? [date1, date2] : [date2, date1];        
        const yearDifference = laterDate.getFullYear() - earlierDate.getFullYear();
        const monthDifference = laterDate.getMonth() - earlierDate.getMonth();
        const dayDifference = laterDate.getDate() - earlierDate.getDate();
      
        if (yearDifference < 1) {
          return true;
        }
        
        if (yearDifference === 1) {
          if (monthDifference < 0 || (monthDifference === 0 && dayDifference <= 0)) {
            return true;
          }
        }        
        return false;
    },
    isToday(dateString, timezone) {
        const inputDate = new Date(dateString);
        const today = new Date();

        const inputDateStr = inputDate.toLocaleDateString('en-US', {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        const todayStr = today.toLocaleDateString('en-US', {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        return inputDateStr === todayStr;
    },
    isYesterday(dateString, timezone) {
        const inputDate = new Date(dateString);
        const today = new Date();

        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const inputDateStr = inputDate.toLocaleDateString('en-US', {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        const yesterdayStr = yesterday.toLocaleDateString('en-US', {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        return inputDateStr === yesterdayStr;
    },
    isStartAndEndOfMonth(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
    
        const sameMonth = startDate.getMonth() === endDate.getMonth();
        const sameYear = startDate.getFullYear() === endDate.getFullYear();
    
        const isStartOfMonth = startDate.getDate() === 1;
    
        const lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
        const isEndOfMonth = endDate.getDate() === lastDay;
    
        return sameMonth && sameYear && isStartOfMonth && isEndOfMonth;
    },
    isStartAndEndOfYear(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
    
        const sameYear = startDate.getFullYear() === endDate.getFullYear();    
        const isStartOfYear = startDate.getMonth() === 0 && startDate.getDate() === 1;    
        const isEndOfYear = endDate.getMonth() === 11 && endDate.getDate() === 31;
    
        return sameYear && isStartOfYear && isEndOfYear;
    },
    getTime(dateString) {
        const timeMatch = dateString.match(/T(\d{2}:\d{2}:\d{2})/);
        return timeMatch ? timeMatch[1] : null;
    },
    getCurrentDate() {
        const now = new Date();
        
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
    },
    getCurrentMonthPeriod() {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); 
        const formattedStart = `${startOfMonth.getFullYear()}-${String(startOfMonth.getMonth() + 1).padStart(2, '0')}-${String(startOfMonth.getDate()).padStart(2, '0')}`;
        const formattedEnd = `${endOfMonth.getFullYear()}-${String(endOfMonth.getMonth() + 1).padStart(2, '0')}-${String(endOfMonth.getDate()).padStart(2, '0')}`;
    
        return { start: formattedStart, end: formattedEnd };
    },
    getMonthAndYear(dateString) {
        const date = new Date(dateString);
    
        const formatter = new Intl.DateTimeFormat('ru-RU', { 
            year: 'numeric', 
            month: 'long' 
        });
    
        return this.capitalizeFirstLetter(formatter.format(date));
    },
    getCurrentMonthAndYear() {
        const today = new Date();
        const monthName = new Intl.DateTimeFormat('ru-RU', { year: "numeric", month: 'long' }).format(today);
        return this.capitalizeFirstLetter(monthName);
    },
    getCurrentYearPeriod() {
        const today = new Date();    
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        const endOfYear = new Date(today.getFullYear(), 11, 31);
        const formattedStart = `${startOfYear.getFullYear()}-${String(startOfYear.getMonth() + 1).padStart(2, '0')}-${String(startOfYear.getDate()).padStart(2, '0')}`;
        const formattedEnd = `${endOfYear.getFullYear()}-${String(endOfYear.getMonth() + 1).padStart(2, '0')}-${String(endOfYear.getDate()).padStart(2, '0')}`;
    
        return { start: formattedStart, end: formattedEnd };
    },
    getYear(dateString) {
        const date = new Date(dateString);
        return `${date.getFullYear()} год`;
    },
    getCurrentYear() {
        const today = new Date(); 
        return `${today.getFullYear()} год`;
    },
    getCurrentDateTime() {
        const now = new Date();
        
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getPreviousDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() - 1);
        return date.toISOString().split('T')[0];
    },
    getNextsDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return date.toISOString().split('T')[0];
    },
    getFirstDayOfPreviousMonth(dateString) {
        const date = new Date(dateString);    
        const firstDayPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);    
        const year = firstDayPreviousMonth.getFullYear();
        const month = String(firstDayPreviousMonth.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayPreviousMonth.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getLastDayOfPreviousMonth(dateString) {
        const date = new Date(dateString);
        date.setDate(1);
        date.setMonth(date.getMonth() - 1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const lastDay = new Date(year, date.getMonth() + 1, 0).getDate();

        return `${year}-${month}-${lastDay}`;
    },
    getFirstDayOfNextMonth(dateString) {
        const date = new Date(dateString);    
        const firstDayPreviousMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);    
        const year = firstDayPreviousMonth.getFullYear();
        const month = String(firstDayPreviousMonth.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayPreviousMonth.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getLastDayOfNextMonth(dateString) {
        const date = new Date(dateString);
        date.setDate(1);
        date.setMonth(date.getMonth() + 1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const lastDay = new Date(year, date.getMonth() + 1, 0).getDate();

        return `${year}-${month}-${lastDay}`;
    },
    getPreviousYear(dateString) {
        const date = new Date(dateString);
        date.setFullYear(date.getFullYear() - 1);
        return date.toISOString().split('T')[0];
    },
    getNextYear(dateString) {
        const date = new Date(dateString);
        date.setFullYear(date.getFullYear() + 1);
        return date.toISOString().split('T')[0];
    },
    getFirstDayOfPreviousYear(dateString) {
        const date = new Date(dateString);
        const firstDayPreviousYear = new Date(date.getFullYear() - 1, 0, 1);    
        const year = firstDayPreviousYear.getFullYear();
        const month = String(firstDayPreviousYear.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayPreviousYear.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getLastDayOfPreviousYear(dateString) {
        const date = new Date(dateString);    
        const lastDayPreviousYear = new Date(date.getFullYear() - 1, 11, 31);
    
        const year = lastDayPreviousYear.getFullYear();
        const month = String(lastDayPreviousYear.getMonth() + 1).padStart(2, '0');
        const day = String(lastDayPreviousYear.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getFirstDayOfNextYear(dateString) {
        const date = new Date(dateString);
        const firstDayPreviousYear = new Date(date.getFullYear() + 1, 0, 1);    
        const year = firstDayPreviousYear.getFullYear();
        const month = String(firstDayPreviousYear.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayPreviousYear.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getLastDayOfNextYear(dateString) {
        const date = new Date(dateString);    
        const lastDayPreviousYear = new Date(date.getFullYear() + 1, 11, 31);
    
        const year = lastDayPreviousYear.getFullYear();
        const month = String(lastDayPreviousYear.getMonth() + 1).padStart(2, '0');
        const day = String(lastDayPreviousYear.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    capitalizeFirstLetter(str) {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getDeviceType() {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            return "phone";
        } else if (screenWidth >= 768 && screenWidth < 1024) {
            return "tablet";
        } else {
            return "laptop";
        }
    },
    getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
        }
        if (/android/i.test(userAgent)) {
          return "Android";
        }   
        return "unknown";
    },
    formatNumberWithSpaces(number) {
        if (number === null || number == undefined) {
            return "";
        }
        return String(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    isNumeric(value) {
        return /^-?\d+(\.\d+)?$/.test(value);
    },
    isEmptyObject(obj) {
        return Object.keys(obj).length === 0;
    },
    formatCharacteristics(characteristics) {
        const result = Object.entries(characteristics)
                             .filter(([key, value]) => key && value !== null && value !== undefined && value !== "")
                             .map(([key, value]) => `${key}: ${value}`).join(" | ");
        return result;
    },
    setDataToLocalStorage(property, data) {
        localStorage.setItem(property, data);
    },
    getDataFromLocalStorage(property) {
        return localStorage.getItem(property);
    },
    deleteDataFromLocalStorage(property) {
        localStorage.removeItem(property);
    },
    discountedPrice(priceWithoutDiscount, discount) {
        if (!priceWithoutDiscount || !discount) {
            return priceWithoutDiscount;
        }
        const price = new Decimal(priceWithoutDiscount);
        const discountValue = new Decimal(discount).div(100);
        const finalPrice = price.minus(price.mul(discountValue));
        return finalPrice.toFixed(2);
    },
    applyMask(phoneNumber, mask) {
        let maskedNumber = "";
        let digitIndex = 0;
  
        for (let i = 0; i < mask.length; i++) {
            const maskChar = mask[i];

            if (maskChar === "#") {
                if (digitIndex < phoneNumber.length) {
                    maskedNumber += phoneNumber[digitIndex];
                    digitIndex++;
                } else {
                    break;
                }
            } else {
                if (digitIndex < phoneNumber.length) {
                    maskedNumber += maskChar;
                } else {
                    break
                }
            }
        }
        return maskedNumber;
    },
    getCountryByPrefix(phoneNumber) {
        const prefixes = Object.keys(phoneMasks).sort((a, b) => b.length - a.length);
      
        for (let prefix of prefixes) {
          if (phoneNumber.startsWith(prefix)) {
            return phoneMasks[prefix];
          }
        }
        return null;
    },
    formatPhoneNumber(phone) {
        if (phone == null || phone == undefined) {
            return "";
        }
        const cleaned = phone.replace(/\D/g, '');
        let result = "";

        if (cleaned.startsWith("7")) {
            result = '+7';
            if (cleaned.length >= 1) {result += ` (${cleaned.slice(1, 4)}`}
            if (cleaned.length >= 4) {result += `) ${cleaned.slice(4, 7)}`}
            if (cleaned.length >= 7) {result += `-${cleaned.slice(7, 9)}`}
            if (cleaned.length >= 9) {result += `-${cleaned.slice(9, 11)}`}
        } else if (cleaned.startsWith("1")) {
            result = "+1";
            if (cleaned.length >= 1) {result += ` (${cleaned.slice(1, 4)}`}
            if (cleaned.length >= 4) {result += `) ${cleaned.slice(4, 7)}`}
            if (cleaned.length >= 7) {result += `-${cleaned.slice(7, 11)}`}
        } else if (cleaned.startsWith("375")) {
            result = '+375';
            if (cleaned.length >= 3) {result += ` (${cleaned.slice(3, 5)}`}
            if (cleaned.length >= 5) {result += `) ${cleaned.slice(5, 8)}`}
            if (cleaned.length >= 8) {result += `-${cleaned.slice(8, 10)}`}
            if (cleaned.length >= 10) {result += `-${cleaned.slice(10, 12)}`}
        } else if (cleaned.startsWith("998")) {
            result = '+998';
            if (cleaned.length >= 3) {result += ` (${cleaned.slice(3, 5)}`}
            if (cleaned.length >= 5) {result += `) ${cleaned.slice(5, 8)}`}
            if (cleaned.length >= 8) {result += `-${cleaned.slice(8, 10)}`}
            if (cleaned.length >= 10) {result += `-${cleaned.slice(10, 12)}`}
        } else if (cleaned.startsWith("86")) {
            result = '+86';
            if (cleaned.length >= 2) {result += ` ${cleaned.slice(2, 5)}`}
            if (cleaned.length >= 5) {result += `-${cleaned.slice(5, 9)}`}
            if (cleaned.length >= 9) {result += `-${cleaned.slice(9, 13)}`}
        } else if (cleaned.startsWith("994")) {
            result = '+994';
            if (cleaned.length >= 3) {result += ` ${cleaned.slice(3, 5)}`}
            if (cleaned.length >= 5) {result += ` ${cleaned.slice(5, 8)}`}
            if (cleaned.length >= 8) {result += `-${cleaned.slice(8, 10)}`}
            if (cleaned.length >= 10) {result += `-${cleaned.slice(10, 12)}`}
        } else if (cleaned.startsWith("90")) {
            result = '+90';
            if (cleaned.length >= 2) {result += ` (${cleaned.slice(2, 5)}`}
            if (cleaned.length >= 5) {result += `) ${cleaned.slice(5, 8)}`}
            if (cleaned.length >= 8) {result += `-${cleaned.slice(8, 10)}`}
            if (cleaned.length >= 10) {result += `-${cleaned.slice(10, 12)}`}
        } else if (cleaned.startsWith("995")) {
            result = '+995';
            if (cleaned.length >= 3) {result += ` (${cleaned.slice(3, 6)}`}
            if (cleaned.length >= 6) {result += `) ${cleaned.slice(6, 8)}`}
            if (cleaned.length >= 8) {result += `-${cleaned.slice(8, 10)}`}
            if (cleaned.length >= 10) {result += `-${cleaned.slice(10, 12)}`}
        } else if (cleaned.startsWith("66")) {
            result = '+66';
            if (cleaned.length >= 2) {result += ` ${cleaned.slice(2, 4)}`}
            if (cleaned.length >= 4) {result += ` ${cleaned.slice(4, 7)}`}
            if (cleaned.length >= 7) {result += `-${cleaned.slice(7, 11)}`}
        }
        
        return result.trim();
    },
    validatePhone(phoneNumber) {
        const clearPhone = phoneNumber.replace(/\D/g, '');

        if (clearPhone.startsWith('7')) {
            return clearPhone.length === 11;
        }

        if (clearPhone.startsWith('1')) {
            return clearPhone.length === 11;
        }

        if (clearPhone.startsWith('375')) {
            return clearPhone.length === 12;
        }

        if (clearPhone.startsWith('998')) {
            return clearPhone.length === 12;
        }

        if (clearPhone.startsWith('86')) {
            return clearPhone.length === 13;
        }

        if (clearPhone.startsWith('994')) {
            return clearPhone.length === 12;
        }

        if (clearPhone.startsWith('90')) {
            return clearPhone.length === 12;
        }

        if (clearPhone.startsWith('995')) {
            return clearPhone.length === 12;
        }

        if (clearPhone.startsWith('66')) {
            return clearPhone.length === 11;
        }

        return false;
    },
    parseNumber(value) {
        if (typeof value == "number") {
            return value
        }
        const number = String(value).replace(/\s+/g, '').replace(/^0+/, '');
        return Number(number) || 0;
    },
    keepOnlyNumbers(string) {
        return string.replace(/\D/g, '');
    }
};