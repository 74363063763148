<template>
    <CatalogWindowComponent v-if="catalogWindowIsOpened" v-model:catalogWindowIsOpened="catalogWindowIsOpened" v-model:operations="operations" :initialCatalogGoods="catalogGoods" :currentKassaId="kassaData.id" forWindow="sale" />
    <AddClientWindow v-if="addClientModalWindowIsOpened" @select-client="selectClient" v-model:addClientIsOpened="addClientModalWindowIsOpened" />
    
    <div>
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[60vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p class="text-2xl mb-8">Продажа</p>
                <div class="grid grid-cols-5 gap-3 mb-8">
                    <PaymentMethodSelection v-model:paymentMethodParam="transactionMethod" labelContent="Метод" class="col-span-2"/>
                    <ClientSelection @openAddClientModalWindow="openAddClientModalWindow" @change="handleSelectionClient" v-model:clientParam="client" labelContent="Клиент" ref="clientSelection" class="col-span-2"/>
                    <DiscountInput ref="addSaleDiscountInput" v-model:discountParam="discount" labelContent="Скидка" class="col-span-1"/>
                </div>
                <div class="w-full mb-8">
                    <div class="grid grid-cols-[repeat(24,minmax(0,1fr))] gap-3 mb-1">
                        <div class="col-span-9 text-sm text-gray-500">Товар / Услуга</div>
                        <div class="col-span-4 text-sm text-gray-500">Количество</div>
                        <div class="col-span-5 text-sm text-gray-500">Цена</div>
                        <div class="col-span-6 text-sm text-gray-500">Сумма</div>
                    </div>
                    <div v-for="(operation, index) in operations" :key="operation" class="mb-4">
                        <div class="grid grid-cols-[repeat(24,minmax(0,1fr))] gap-3 mb-2 relative">
                            <div class="text-sm text-gray-500 flex items-center justify-center absolute top-1/2 -translate-y-1/2 left-[-2rem]">{{ index + 1 }}</div>
                            <GoodSelection @change="selectGood(index)" :ref="`goodSelection-${index}`" @openCatalogWindow="openCatalogWindow" v-model:goodParam="operations[index].good" :goodChoices="goodsToAddSale" class="col-span-9"/>
                            <AmountInput :ref="`operationAmountInput-${index}`" @input="updateTotalPrice(index)" v-model:amountParam="operation.amount" :maxValue="operation.maxAmount" class="col-span-4"/>
                            <PriceInput :ref="`operationPriceInput-${index}`" @input="updateTotalPrice(index)" v-model:priceParam="operation.price_without_discount" :priceCurrency="currency" class="col-span-5"/>
                            <PriceInput v-model:priceParam="operation.total_price" :priceCurrency="currency" :readOnly="true" class="col-span-6"/>
                            <div v-if="operations.length > 1" class="absolute top-1/2 -translate-y-1/2 right-[-2rem] cursor-pointer flex items-center justify-end">
                                <svg @click="deleteOperation(index)" class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z"/></svg>
                            </div>
                        </div>
                        <span v-show="operation.id" class="text-[15px] text-[#181818] opacity-[0.7]">{{ operation.format != 'good' ? "В наличии: ∞": `В наличии: ${operation.maxAmount}`}}</span>
                    </div>
                    <ButtonWithPlus @click="addOperation" buttonContent="Добавить товар"/>
                </div>
                <CommentInput v-model:commentParam="note" labelContent="Комментарий" class="w-full mb-8"/>
                <div class="w-full mb-8 relative flex items-center">
                    <span v-show="totalOfPrices != totalOfPricesWithDiscount" class="absolute top-[-50%] text-sm"><s>{{ $globalMethods.formatNumberWithSpaces(totalOfPrices) }}</s></span>
                    <span class="mr-8">{{ $globalMethods.formatNumberWithSpaces(totalOfPricesWithDiscount) }}</span>
                    <select v-model="currency" class="ring-0 shadow-none border-0 outline-0 focus:ring-0 focus:shadow-none focus:border-0 focus:outline-0">
                        <option v-for="element in currencies" :key="element" :value="element">{{ element }}</option>
                    </select>
                </div>
                <button @click="createNewSale" :disabled="buttonIsDisabled" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
            </div>
        </div>
    </div>
</template>


<script>
    import Decimal from 'decimal.js'; 
    import { mapState } from 'vuex';

    import CatalogWindowComponent from '@/components/laptop/Good/CatalogWindowComponent.vue';
    import AddClientWindow from '@/components/laptop/Client/AddClientWindow.vue';
    import PriceInput from '@/components/InputComponents/PriceInput.vue';
    import AmountInput from '@/components/InputComponents/AmountInput.vue';
    import DiscountInput from '@/components/InputComponents/DiscountInput.vue';
    import CommentInput from '@/components/InputComponents/CommentInput.vue';
    import GoodSelection from '@/components/InputComponents/GoodSelection.vue';
    import ClientSelection from '@/components/InputComponents/ClientSelection.vue';
    import PaymentMethodSelection from '@/components/InputComponents/PaymentMethodSelection.vue';

    import ButtonWithPlus from '@/components/Button/ButtonWithPlus.vue';

    export default {
        name: "AddSaleWindowComponent",
        components: {
            CatalogWindowComponent,
            AddClientWindow,
            PriceInput,
            AmountInput,
            DiscountInput,
            CommentInput,
            GoodSelection,
            ClientSelection,
            PaymentMethodSelection,
            ButtonWithPlus,
        },
        props: {
            addSaleWindowIsOpened: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                catalogWindowIsOpened: false,
                addClientModalWindowIsOpened: false,

                catalogGoods: [],

                transactionMethod: null,
                client: null,
                discount: 0,
                operations: [{amount: 1, maxAmount: Infinity, price_without_discount: 0, total_price: 0}],
                currency: null,
                note: "",

                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["companyData", "kassaData", "clients", "kassaGoods", "currencies", "labels"]),
            goodsToAddSale() {
                let saleGoods = this.kassaGoods.filter(good => good.type == "income" && ["good", "service"].includes(good.format));
                let usedGoods = this.operations.map(operation => operation.good ? operation.good.id : undefined);
                let result = saleGoods.filter(good => !usedGoods.includes(good.id));
                return result;
            },
            totalOfPrices() {
                const result = this.operations.reduce((acc, operation) => {
                    let price;
                    if (operation.total_price) {
                        price = new Decimal(String(operation.total_price).replace(/\s+/g, ''));
                    } else {
                        price = 0
                    }
                    return acc.plus(price);
                }, new Decimal(0));

                return result.toFixed(2);
            },
            totalOfPricesWithDiscount() {
                const result = this.$globalMethods.discountedPrice(this.totalOfPrices, this.discount);
                return result
            },
        },
        methods: {
            validateNewSaleData() {
                if (Number(this.discount) >= 100 || Number(this.discount) < 0) {
                    const addSaleDiscountInput = this.$refs.addSaleDiscountInput.$refs.discountInput;
                    addSaleDiscountInput.setCustomValidity("Скидка должна быть от 0 до 99");
                    addSaleDiscountInput.reportValidity();
                    return false;
                }
                for (const [index, operation] of this.operations.entries()) {
                    if (!operation.id) {
                        const goodSelectionInput = this.$refs[`goodSelection-${index}`][0].$refs.goodSelectionInput;
                        goodSelectionInput.setCustomValidity("Поле товара обязательно для заполнения");
                        goodSelectionInput.reportValidity();
                        return false;
                    }
                    if (!operation.amount || operation.amount <= 0) {
                        const operationAmountInput = this.$refs[`operationAmountInput-${index}`][0].$refs.amountInput;
                        operationAmountInput.setCustomValidity("Поле количества товара обязательно для заполнения");
                        operationAmountInput.reportValidity();
                        return false;
                    }
                    if (operation.amount > operation.maxAmount && operation.format == 'good' && !this.companyData.saleWithZeroRemainingIsAvailable) {
                        const operationAmountInput = this.$refs[`operationAmountInput-${index}`][0].$refs.amountInput;
                        operationAmountInput.setCustomValidity("Количество товара не может превышать доступное количество в наличии");
                        operationAmountInput.reportValidity();
                        return false;
                    }
                    if (Number(operation.price_without_discount) <= 0) {
                        const operationPriceInput = this.$refs[`operationPriceInput-${index}`][0].$refs.priceInput;
                        operationPriceInput.setCustomValidity("Поле цены должно быть строго больше нуля");
                        operationPriceInput.reportValidity();
                        return false;
                    }
                }
                return true;
            },
            async createNewSale() {
                for (const operation of this.operations) {
                    operation.amount = Number(operation.amount);
                    operation.price_without_discount = Number(operation.price_without_discount);
                    operation.price = Number(this.$globalMethods.discountedPrice(operation.price_without_discount, this.discount));
                }
                if (!this.validateNewSaleData()) {
                    return;
                }
                this.buttonIsDisabled = true;
                const result = await this.$store.dispatch("createNewSale", {
                    transactionMethod: this.transactionMethod,
                    client: this.client,
                    operations: this.operations,
                    discount: this.discount,
                    note: this.note,
                    currency: this.currency,
                })
                if (result.successfully) {
                    this.closeWindow();
                    this.$notify("Продажа успешно добавлена");
                }
                this.buttonIsDisabled = false;
            },
            addOperation() {
                this.operations.push({amount: 1, maxAmount: Infinity, price_without_discount: 0, total_price: 0})
            },
            deleteOperation(index) {
                this.operations.splice(index, 1);
            },
            updateTotalPrice(index) {
                const operation = this.operations[index];
                if (operation.price_without_discount && operation.amount) {
                    operation.total_price = this.$globalMethods.formatNumberWithSpaces(Number(new Decimal(operation.price_without_discount).mul(operation.amount).toString()));
                } else {
                    operation.total_price = this.$globalMethods.formatNumberWithSpaces(0);
                }
            },
            selectGood(index) {
                const operation = this.operations[index];
                if (!operation.good) {
                    return;
                }
                operation.id = operation.good.id;
                operation.good_verbose_id = operation.good.verbose_id;
                operation.format = operation.good.format;
                operation.price_without_discount = Number(operation.good.price);

                if (operation.good.format == "good") {
                    operation.maxAmount = operation.good.amount[this.kassaData.id] || 0;
                } else {
                    operation.maxAmount = Infinity;
                }

                if (!operation.good.maxAmount && operation.good.format == "good") {
                    operation.amount = 0;
                } else {
                    operation.amount = 1;
                }

                operation.total_price = this.$globalMethods.formatNumberWithSpaces(Number(new Decimal(operation.price_without_discount).mul(operation.amount).toString()));
            },
            handleSelectionClient() {
                const client = this.clients.find(client => client.id == this.client);
                if (client) {
                    this.discount = client.discount;
                }
            },
            selectClient(client) {
                this.client = client.id;
                this.discount = client.discount;
                const clientSelection = this.$refs.clientSelection;
                clientSelection.searchValue = client.name;
                clientSelection.selectedClientId = client.id;
            },
            openCatalogWindow() {
                this.catalogGoods = this.kassaGoods.filter(good => good.type == "income" && ["good", "service"].includes(good.format));
                this.catalogWindowIsOpened = true;
            },
            openAddClientModalWindow() {
                document.body.classList.add("overflow-hidden");
                this.addClientModalWindowIsOpened = true;
            },
            closeWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:addSaleWindowIsOpened", false);
                this.currency = this.companyData.main_currency;
                this.client = null;
                this.discount = 0;
                this.operations = [];
            },
            openWindow() {
                document.body.classList.add("overflow-hidden");
                this.currency = this.kassaData.default_currency;
                this.transactionMethod = this.kassaData.default_payment;
            },
        },
        created() {
            this.openWindow();
        },
        mounted() {

        },
    }
</script>