<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[37.5vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-xl mb-8">Обновление сотрудника</p>
                    <div class="flex flex-col items-stretch gap-4">
                        <TextInput ref="nameInput" v-model:textParam="name" labelContent="Имя"/>
                        <PhoneInputComponent ref="phoneInput" v-model:inputParam="phone" labelContent="Телефон"/>
                        <KassaMultiSelection ref="kassaSelection" v-model:multiSelectionParam="selectedKassas"/>
                        <div>
                            <label class="mb-1 block text-sm text-gray-500">Права доступа</label>
                            <div class="rounded border border-custom-blue">
                                <div class="p-4 grid gap-4">
                                    <div class="grid gap-2">
                                        <CheckboxInput v-model:checkboxParam="viewGoodsAndCharacteristicsPermission" labelContent="Просматривать товары и характеристики"/>
                                        <div v-if="viewGoodsAndCharacteristicsPermission" class="pl-4 grid gap-2">
                                            <CheckboxInput v-model:checkboxParam="editGoodsAndCharacteristicsPermission" labelContent="Редактировать товары и их характеристики"/>
                                            <CheckboxInput v-model:checkboxParam="deleteGoodsAndCharacteristicsPermission" labelContent="Удалять товары и их характеристики"/>
                                            <CheckboxInput v-model:checkboxParam="addGoodsAndCharacteristicsPermission" labelContent="Добавлять товары и их характеристики"/>
                                        </div>
                                    </div>
                                    <CheckboxInput v-model:checkboxParam="createKassaTransactionsPermission" labelContent="Создавать кассовые операции"/>
                                    <div class="grid gap-2">
                                        <CheckboxInput v-model:checkboxParam="deleteLastKassaTransactionPermission" labelContent="Удалять последнюю кассовую операцию"/>
                                        <div v-if="deleteLastKassaTransactionPermission" class="pl-4 grid gap-2">
                                            <CheckboxInput v-model:checkboxParam="deleteKassaTransactionsByLastDayPermission" labelContent="Удалять кассовые операции за последний день"/>
                                        </div>
                                    </div>
                                    <CheckboxInput v-model:checkboxParam="createMovingsAndSupplyingsPermission" labelContent="Создавать поставки и перемещения товаров"/>
                                    <CheckboxInput v-model:checkboxParam="viewFullClientBaseUploadAndDownloadClientsPermission" labelContent="Просмотр полной клиентской базы. Загрузка и выгрузка клиентов"/>
                                    <CheckboxInput v-model:checkboxParam="deleteClientPermission" labelContent="Удалять клиентов из базы"/>
                                    <CheckboxInput v-model:checkboxParam="editDiscountPolicyPermission" labelContent="Редактировать политики скидок"/>
                                    <CheckboxInput v-model:checkboxParam="viewBalancesForPastPeriods" labelContent="Просмотр балансов за прошлые периоды"/>
                                </div>
                            </div>
                        </div>
                        <PriceInput v-model:priceParam="salaryPerDayShift" :priceCurrency="companyData.main_currency" labelContent="Ставка за смену (день)"/>
                        <PriceInput v-model:priceParam="salaryPerDayHour" :priceCurrency="companyData.main_currency" labelContent="Ставка за час (день)"/>
                        <PriceInput v-model:priceParam="salaryPerNightShift" :priceCurrency="companyData.main_currency" labelContent="Ставка за смену (ночь)"/>
                        <PriceInput v-model:priceParam="salaryPerNightHour" :priceCurrency="companyData.main_currency" labelContent="Ставка за час (ночь)"/>
                        <DiscountInput v-model:discountParam="percentOfSale" labelContent="Процент с продаж" />
                        <button @click.stop="updateStaff" :disabled="buttonIsDisabled" ref="sendRequestToAddGoodButton" type="button" class="mt-4 text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import PhoneInputComponent from '@/components/InputComponents/PhoneInputComponent.vue';
    import PriceInput from '@/components/InputComponents/PriceInput.vue';
    import DiscountInput from '@/components/InputComponents/DiscountInput.vue';
    import TextInput from '@/components/InputComponents/TextInput.vue';
    import KassaMultiSelection from '@/components/InputComponents/KassaMultiSelection.vue';
    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';

    export default {
        name: "UpdateStaffWindow",
        components: {
            PhoneInputComponent,
            PriceInput,
            DiscountInput,
            TextInput,
            KassaMultiSelection,
            CheckboxInput,
        },
        props: {
            windowIsOpened: {
                type: Boolean,
                required: true,
            },
            staff: {
                type: [String, Number],
                required: true,
            }
        },
        computed: {
            ...mapState(["companyData", "kassas", "staffs"]),
        },
        data() {
            return {
                name: null,
                phone: null,
                selectedKassas: [],
                salaryPerDayShift: null,
                salaryPerDayHour: null,
                salaryPerNightShift: null,
                salaryPerNightHour: null,
                percentOfSale: null,
                buttonIsDisabled: false,

                viewGoodsAndCharacteristicsPermission: false,
                editGoodsAndCharacteristicsPermission: false,
                deleteGoodsAndCharacteristicsPermission: false,
                addGoodsAndCharacteristicsPermission: false,

                createKassaTransactionsPermission: false,
                deleteLastKassaTransactionPermission: false,
                deleteKassaTransactionsByLastDayPermission: false,

                createMovingsAndSupplyingsPermission: false,
                viewFullClientBaseUploadAndDownloadClientsPermission: false,
                deleteClientPermission: false,

                editDiscountPolicyPermission: false,

                viewBalancesForPastPeriods: false,
            }
        },
        methods: {
            validateData() {
                if (!this.name) {
                    const nameInput = this.$refs.nameInput.$refs.textInput;
                    nameInput.setCustomValidity("Поле имени обязательное");
                    nameInput.reportValidity();
                    return false;
                }
                if (!this.phone) {
                    const phoneInput = this.$refs.phoneInput.$refs.phoneInput;
                    phoneInput.setCustomValidity("Поле теелфона обязатеьное");
                    phoneInput.reportValidity();
                    return false;
                }
                if (!this.$globalMethods.validatePhone(this.phone)) {
                    const phoneInput = this.$refs.phoneInput.$refs.phoneInput;
                    phoneInput.setCustomValidity("Введите корректный номер телефона");
                    phoneInput.reportValidity();
                    return false;
                }
                if (Object.values(this.selectedKassas).every(value => value === false)) {
                    const kassaSelection = this.$refs.kassaSelection;
                    kassaSelection.$refs.kassaSelectionBlock.scrollIntoView({ behavior: "smooth", block: "start" });
                    kassaSelection.$refs.kassaSelectionButton.click();
                    return false;
                }
                return true;
            },
            async updateStaff() {
                if (!this.validateData()) {
                    return;
                }
                this.buttonIsDisabled = true;
                
                const shopIds = [];
                for (const shopId in this.selectedKassas) {
                    if (this.selectedKassas[shopId]) {
                        shopIds.push(Number(shopId));
                    }
                }

                if (!this.viewGoodsAndCharacteristicsPermission) {
                    this.editGoodsAndCharacteristicsPermission = false;
                    this.deleteGoodsAndCharacteristicsPermission = false;
                    this.addGoodsAndCharacteristicsPermission = false;
                }

                if (!this.deleteLastKassaTransactionPermission) {
                    this.deleteKassaTransactionsByLastDayPermission = false;
                }

                const data = {
                    staffId: this.staff.id,
                    name: this.name, 
                    phone: this.phone,
                    salary_per_day_hour: Number(this.salaryPerDayHour),
                    salary_per_day_shift: Number(this.salaryPerDayShift),
                    salary_per_night_hour: Number(this.salaryPerNightHour),
                    salary_per_night_shift: Number(this.salaryPerNightShift),
                    percent_of_sale: Number(this.percentOfSale),
                    is_active: this.staff.is_active,
                    shop_ids: shopIds,
                    view_goods_and_characteristics_permission: this.viewGoodsAndCharacteristicsPermission,
                    edit_goods_and_characteristics_permission: this.editGoodsAndCharacteristicsPermission,
                    delete_goods_and_characteristics_permission: this.deleteGoodsAndCharacteristicsPermission,
                    add_goods_and_characteristics_permission: this.addGoodsAndCharacteristicsPermission,
                    create_kassa_transactions_permission: this.createKassaTransactionsPermission,
                    delete_last_kassa_transaction_permission: this.deleteLastKassaTransactionPermission,
                    delete_kassa_transactions_by_last_day_permission: this.deleteKassaTransactionsByLastDayPermission,
                    create_movings_and_supplyings_permission: this.createMovingsAndSupplyingsPermission,
                    view_full_client_base_upload_and_download_clients_permission: this.viewFullClientBaseUploadAndDownloadClientsPermission,
                    delete_client_permission: this.deleteClientPermission,
                    edit_discount_policy_permission: this.editDiscountPolicyPermission,
                    view_balances_for_past_periods_permission: this.viewBalancesForPastPeriods,
                }
                const result = await this.$store.dispatch("updateStaff", data);
                if (result.successfully) {
                    this.$notify("Сотрудник успешно обновлен");
                    this.closeWindow();
                }
                this.buttonIsDisabled = false;
            },
            closeWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:windowIsOpened", false);
            },
            initData() {
                this.name = this.staff.name;
                this.phone = this.staff.phone;
                this.salaryPerDayShift = this.staff.salary_per_day_shift;
                this.salaryPerDayHour = this.staff.salary_per_day_hour;
                this.salaryPerNightShift = this.staff.salary_per_night_shift;
                this.salaryPerNightHour = this.staff.salary_per_night_hour;
                this.percentOfSale = this.staff.percent_of_sale;
                this.selectedKassas = {};
                for (const kassa of this.kassas) {
                    if (this.staff.shops.includes(kassa.id)) {
                        this.selectedKassas[kassa.id] = true;
                    } else {
                        this.selectedKassas[kassa.id] = false;
                    }
                }
                this.viewGoodsAndCharacteristicsPermission = this.staff.view_goods_and_characteristics_permission;
                this.editGoodsAndCharacteristicsPermission = this.staff.edit_goods_and_characteristics_permission;
                this.deleteGoodsAndCharacteristicsPermission = this.staff.delete_goods_and_characteristics_permission;
                this.addGoodsAndCharacteristicsPermission = this.staff.add_goods_and_characteristics_permission;
                this.createKassaTransactionsPermission = this.staff.create_kassa_transactions_permission;
                this.deleteLastKassaTransactionPermission = this.staff.delete_last_kassa_transaction_permission;
                this.deleteKassaTransactionsByLastDayPermission = this.staff.delete_kassa_transactions_by_last_day_permission;
                this.createMovingsAndSupplyingsPermission = this.staff.create_movings_and_supplyings_permission;
                this.viewFullClientBaseUploadAndDownloadClientsPermission = this.staff.view_full_client_base_upload_and_download_clients_permission;
                this.deleteClientPermission = this.staff.delete_client_permission;
                this.editDiscountPolicyPermission = this.staff.edit_discount_policy_permission;
                this.viewBalancesForPastPeriods = this.staff.view_balances_for_past_periods_permission;
            },
        },
        created() {
            this.initData();
        }
    }
</script>