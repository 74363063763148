<template>
    <div>
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
        <input v-model="param" @input="handleInput" ref="textInput" :readonly="readOnly" :disabled="readOnly" :id="uniqueId" :type="type" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
    </div>
</template>


<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "TextInput",
        props: {
            textParam: {
                type: [String, Number, null],
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: "text",
            }
        },
        computed: {
            param: {
                get() {
                    return this.textParam;
                },
                set(value) {
                    if (this.readOnly) {
                        return;
                    }
                    this.$emit("update:textParam", value);
                }
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
            }
        },
        methods: {
            handleInput() {
                this.$refs.textInput.setCustomValidity("");
                this.$emit("input");
            },
        }
    }
</script>