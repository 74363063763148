<template>
    <div ref="clientSelectionBlock" class="relative">
        <div>
            <div class="flex items-center mb-1">
                <label :for="uniqueId" class="text-sm text-gray-500 mr-2">{{ labelContent }}</label>
                <svg @click="openAddClientModalWindow" class="cursor-pointer w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M438-274h86v-162h162v-86H524v-164h-86v164H274v86h164v162Zm42.07 206q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Z"></path></svg>
            </div>
            <input v-model="searchedClientParam" type="text" :id="uniqueId" @focus="openSelection" @input="openSelection" @keydown.enter="handleEnterPress" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-3">
        </div>

        <div v-show="selectionIsOpened" class="mt-2 z-10 bg-white absolute rounded-lg shadow w-[17.675rem] dark:bg-gray-700">
            <div @click.stop class="flex items-start flex-col max-h-52 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200">
                <RadioInput v-for="client in filteredClients" :key="client.id" @change="handleSelecionClient(client)" v-model:radioParam="selectedClientId" name="client" :value="client.id" :labelContent="client.name"/>
                <p v-if="searchValue.length < 5" class="text-center mt-2 w-full">Введите минимум 5 символов</p>
                <p v-else-if="filteredClients.length == 10" class="text-center mt-2 w-full">Если вы не нашли нужного клиента, уточните поисковой запрос</p>
                <p v-else-if="filteredClients.length == 0" class="text-center mt-2 w-full">Не найдено клиентов</p>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';
    import { v4 as uuidv4 } from 'uuid';

    import RadioInput from './RadioInput.vue';


    export default {
        name: "ClientSelection",
        components: {
            RadioInput,
        },
        props: {
            clientParam: {
                type: [Number, null],
                required: false,
            },
            labelContent: {
                type: String,
                default: "",
            }
        },
        computed: {
            ...mapState(["clients"]),
            filteredClients() {
                if (this.searchValue.length < 5) {
                    return []
                }
                return this.clients.filter(client => client.name.toLowerCase().includes(this.searchValue.toLowerCase()) || client.phone.replace(/[+\-\s()]/g, "").includes(this.searchValue.replace(/[+\-\s()]/g, "")) || client.email.toLowerCase().includes(this.searchValue.toLowerCase()))
            },
            selectedClient() {
                return this.clients.find(client => client.id == this.clientParam);
            },
            searchedClientParam: {
                get() {
                    return this.selectedClient ? this.selectedClient.name : this.searchValue;
                },
                set(value) {
                    this.$emit("update:clientParam", null);
                    this.selectedClientId = null;
                    this.searchValue = value;
                }
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
                selectionIsOpened: false,
                searchValue: "",
                selectedClientId: null,
            }
        },
        methods: {
            handleEnterPress() {
                const firstClient = this.filteredClients[0];
                if (firstClient) {
                    this.handleSelecionClient(firstClient);
                }
            },
            handleSelecionClient(client) {
                this.$emit("update:clientParam", client.id);
                this.$emit("change");
                this.selectedClientId = client.id;
                this.selectionIsOpened = false;
                this.searchValue = client.name;
            },
            openSelection() {
                this.selectionIsOpened = true;
            },
            openAddClientModalWindow() {
                this.$emit("openAddClientModalWindow");
            },
            autoCloseClientSelectionBlock() {
                const clientSelectionBlock = this.$refs.clientSelectionBlock;
                document.body.addEventListener("click", (event) => {
                    if (clientSelectionBlock.contains(event.target)) {
                        return;
                    }
                    this.selectionIsOpened = false;
                })
            },
        },
        mounted() {
            this.autoCloseClientSelectionBlock();
        },
    }
</script>