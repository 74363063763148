<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[35vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeAddGoodModalWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <div class="flex items-center justify-between mb-8">
                        <p class="text-xl">Новая позиция</p>
                        <div @click="openUploadGoodsCSVModalWindow" class="flex items-center text-custom-blue text-sm cursor-pointer">
                            <svg class="w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>
                            <p>Загрузить через CSV</p>
                        </div>
                    </div>
                    <div class="flex flex-col items-stretch gap-4">
                        <div class="grid grid-cols-3">
                            <RadioInput ref="goodFormatInput" v-model:radioParam="goodFormat" name="good-type" value="good" labelContent="Товар"/>
                            <RadioInput v-model:radioParam="goodFormat" name="good-type" value="service" labelContent="Услуга"/>
                            <RadioInput v-model:radioParam="goodFormat" name="good-type" value="operation" labelContent="Операция"/>
                        </div>
                        <div v-if="goodFormat == 'operation'" class="grid grid-cols-2">
                            <RadioInput ref="goodTypeInput" v-model:radioParam="goodType" name="good-price-type" value="income" labelContent="Приходная"/>
                            <RadioInput v-model:radioParam="goodType" name="good-price-type" value="expense" labelContent="Расходная"/>
                        </div>
                        <KassaMultiSelection ref="kassaSelectionBlock" v-model:multiSelectionParam="selectedKassas"/>
                        <TextInput ref="goodNameInput" v-model:textParam="goodName" labelContent="Наименование"/>
                        <TextInput ref="goodVerboseIdInput" @input="handleVerboseIdInput" v-model:textParam="goodVerboseId" labelContent="Артикул"/>
                        <PriceInput ref="addGoodPriceInput" v-model:priceParam="goodPrice" labelContent="Цена"/>
                        <div v-if="goodFormat != 'operation'" class="flex flex-col items-stretch gap-4">
                            <div v-for="(characteristicValue, characteristicName) in companyData.good_characteristics" :key="characteristicName">
                                <label :for="`add-good-characteristic-${characteristicName}`" class="mb-1 block text-sm text-gray-500">{{ characteristicName }}</label>
                                <select v-if="Array.isArray(characteristicValue)" v-model="goodCharacteristics[characteristicName]" :ref="`addGoodCharacteristic${characteristicName}Input`" :id="`add-good-characteristic-${characteristicName}`" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                                    <option :value="null"></option>
                                    <option v-for="value in characteristicValue" :key="value" :value="value">{{ value }}</option>
                                </select>
                                <input v-else v-model="goodCharacteristics[characteristicName]" :ref="`addGoodCharacteristic${characteristicName}Input`" :id="`add-good-characteristic-${characteristicName}`" type="text" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                            </div>
                        </div>
                        <button @click.stop="sendRequestToAddGood" ref="sendRequestToAddGoodButton" :disabled="buttonIsDisabled" type="button" class="mt-4 text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import KassaMultiSelection from '@/components/InputComponents/KassaMultiSelection.vue';
    import TextInput from '@/components/InputComponents/TextInput.vue';
    import PriceInput from '@/components/InputComponents/PriceInput.vue';
    import RadioInput from '@/components/InputComponents/RadioInput.vue';


    export default {
        name: "AddGoodModalWindowComponent",
        components: {
            KassaMultiSelection,
            TextInput,
            PriceInput,
            RadioInput,
        },
        props: {
            addGoodIsOpened: {
                type: Boolean,
                required: true,
            },
            uploadGoodsCSVIsOpened: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                goodFormat: null,
                goodType: null,
                goodName: null,
                goodVerboseId: null,
                goodPrice: null,
                goodCharacteristics: {},
                selectedKassas: {},
                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["companyData", "kassas"]),
            selectedKassasVerboseName() {
                let result = [];
                for (const [key, value] of Object.entries(this.selectedKassas)) {
                    if (value) {
                        result.push(this.kassas.find(kassa => kassa.id == key).name);
                    }
                }
                return result.join(" | ");
            },
        },
        methods: {
            handleVerboseIdInput() {
                this.goodVerboseId = this.goodVerboseId.replace(/[^a-zA-Z0-9]/g, '').slice(0, 6);
            },
            validateDataToAddGood() {
                if (!this.goodFormat) {
                    const goodFormatInput = this.$refs.goodFormatInput.$refs.radioInput;
                    goodFormatInput.setCustomValidity("Выберите тип");
                    goodFormatInput.reportValidity();
                    return false;
                }

                if (this.goodFormat == "operation" && !this.goodType) {
                    const goodTypeInput = this.$refs.goodTypeInput.$refs.radioInput;
                    goodTypeInput.setCustomValidity("Выберите тип операции");
                    goodTypeInput.reportValidity();
                    return false;
                }

                if (!this.goodName) {
                    const goodNameInput = this.$refs.goodNameInput.$refs.textInput;
                    goodNameInput.setCustomValidity("Наименование обязательное");
                    goodNameInput.reportValidity();
                    return false;
                }

                if (this.goodVerboseId && this.goodVerboseId.length != 6) {
                    const goodVerboseIdInput = this.$refs.goodVerboseIdInput.$refs.textInput;
                    goodVerboseIdInput.setCustomValidity("Артикул должен содержать ровно 6 символов");
                    goodVerboseIdInput.reportValidity();
                    return false;
                }

                if (Object.values(this.selectedKassas).every(value => value === false)) {
                    const kassaMultiSelectionComponent = this.$refs.kassaSelectionBlock;
                    const kassaSelectionBlock = kassaMultiSelectionComponent.$refs.kassaSelectionBlock;
                    kassaSelectionBlock.scrollIntoView({ behavior: "smooth", block: "start" });
                    kassaMultiSelectionComponent.isOpened = true;
                    return false;
                }

                return true;
            },
            async sendRequestToAddGood() {
                if (!this.validateDataToAddGood()) {
                    return;
                }

                this.buttonIsDisabled = true;

                let goodType;
                if (this.goodFormat == "operation") {
                    goodType = this.goodType;
                } else {
                    goodType = "income";
                }

                let goodPrice;
                if (this.goodPrice) {
                    goodPrice = Number(this.goodPrice);
                } else {
                    goodPrice = 0;
                }

                const selectedKassas = {};
                for (const kassa in this.selectedKassas) {
                    selectedKassas[Number(kassa)] = this.selectedKassas[kassa];
                }

                const characteristics = {};
                for (const [characteristicName, characteristicValue] of Object.entries(this.goodCharacteristics)) {
                    if (characteristicValue != null) {
                        characteristics[characteristicName] = characteristicValue;
                    }
                }

                const payload = {
                    format: this.goodFormat,
                    type: goodType,
                    name: this.goodName,
                    verbose_id: this.goodVerboseId,
                    price: goodPrice,
                    characteristics: characteristics,
                    shops_data: selectedKassas,
                }

                const result = await this.$store.dispatch("createNewGood", payload);
                if (result.successfully) {
                    this.$notify("Позиция успешно добавлена в каталог");
                    this.clearAddGoodModalWindow();
                }

                this.buttonIsDisabled = false;
            },
            clearAddGoodModalWindow() {
                this.goodName = null;
                this.goodVerboseId = null;
                this.goodPrice = null;
                this.goodCharacteristics = {};
                this.selectedKassas = {};
                this.initData();
            },
            closeAddGoodModalWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:addGoodIsOpened", false);
            },
            openUploadGoodsCSVModalWindow() {
                this.$emit("update:addGoodIsOpened", false);
                this.$emit("update:uploadGoodsCSVIsOpened", true);
            }, 
            initData() {
                for (const kassa of this.kassas) {
                    this.selectedKassas[kassa.id] = false;
                }
                for (const characteristicName in this.companyData.good_characteristics) {
                    this.goodCharacteristics[characteristicName] = null;
                }
            },
        },
        created() {
            this.initData();
        },
        mounted() {

        },
    }
</script>