import { createRouter, createWebHistory } from 'vue-router';

import DashboardView from '@/views/DashboardView.vue';
import KassaView from '@/views/KassaView.vue';
import ClientsAndDiscountsView from "@/views/ClientsAndDiscountsView.vue";
import CatalogAndSuppliesView from '@/views/CatalogAndSuppliesView.vue';
import StaffView from '@/views/StaffView.vue';
import PersonalAccountView from '@/views/PersonalAccountView.vue';
import SignUpView from "@/views/SignUpView.vue";
import SignInView from "@/views/SignInView.vue";


const routes = [
  {
    path: '/dashboard/',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      title: "Кассы",
    },
  },
  {
    path: '/kassa/',
    name: 'kassa',
    component: KassaView,
    meta: {
      title: "Касса",
    },
  },
  {
    path: "/clients/",
    name: "clients",
    component: ClientsAndDiscountsView,
    meta: {
      title: "Клиенты",
    },
  },
  {
    path: "/catalog/",
    name: "catalog",
    component: CatalogAndSuppliesView,
    meta: {
      title: "Каталог",
    },
  },
  {
    path: "/staffs/",
    name: "staffs",
    component: StaffView,
    meta: {
      title: "Сотрудники",
    },
  },
  {
    path: "/account/",
    name: "account",
    component: PersonalAccountView,
    meta: {
      title: "Личный кабинет",
    },
  },
  {
    path: "/auth/sign-up/",
    name: "sign-up",
    component: SignUpView,
    meta: {
      title: "Регистрация аккаунта",
    }
  },
  {
    path: "/auth/sign-in/",
    name: "sign-in",
    component: SignInView,
    meta: {
      title: "Вход в систему",
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'QKASSA';
  next();
});

export default router
