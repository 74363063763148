<template>
    <div ref="kassaSelectionBlock">
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
        <button @click="openCloseKassaSelection" ref="kassaSelectionButton" :id="uniqueId" class="w-full min-h-[2.375rem] flex justify-between items-center py-2 px-4 text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500" type="button">
            <span :class="selectedKassa == 'write-off' ? 'text-red-500': ''" class="whitespace-nowrap overflow-hidden text-ellipsis shrink-0">{{ verboseSelectedKassa }}</span> 
            <IconSelectArrowDown class="w-2.5 h-2.5 shrink-0"/>
        </button>
        <div v-show="kassaSelectionIsOpened" ref="kassaSelection" class="z-10 bg-white absolute rounded-lg shadow">
            <div class="max-h-52 px-3 py-3 overflow-y-auto text-sm text-gray-700">
                <RadioInput v-if="withWriteOff" @change="handleChangeKassaSelection" v-model:radioParam="selectedKassa" :name="uniqueId" value="write-off">
                    <span class="text-red-500">Списание</span>
                </RadioInput>
                <RadioInput v-if="withMainWarehouse" @change="handleChangeKassaSelection" v-model:radioParam="selectedKassa" :name="uniqueId" value="main" labelContent="Главный склад"/>
                <RadioInput v-for="kassa in filteredKassas" :key="kassa.id" @change="handleChangeKassaSelection" v-model:radioParam="selectedKassa" :name="uniqueId" :value="kassa.id" :labelContent="kassa.name"/>
                <div v-if="filteredKassas.length == 0 && !withMainWarehouse && !withWriteOff" class="text-center">У вас пока нет доступных складов</div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';
    import { v4 as uuidv4 } from 'uuid';

    import IconSelectArrowDown from '@/components/Icon/IconSelectArrowDown.vue';
    import RadioInput from './RadioInput.vue';


    export default {
        name: "KassaSelection",
        components: {
            IconSelectArrowDown,
            RadioInput,
        },
        props: {
            kassaParam: {
                type: Object,
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            },
            withMainWarehouse: {
                type: Boolean,
                default: false,
            },
            withWriteOff: {
                type: Boolean,
                default: false,
            },
            withoutKassas: {
                type: Array,
                default: () => [],
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
                kassaSelectionIsOpened: false,
                selectedKassa: null,
            }
        },
        computed: {
            ...mapState(["kassas"]),
            verboseSelectedKassa() {
                if (!this.selectedKassa) {
                    return ""
                }
                if (this.selectedKassa == "main") {
                    return "Главный склад"
                }
                if (this.selectedKassa == "write-off") {
                    return "Списание"
                }
                return this.kassas.find(kassa => kassa.id == this.selectedKassa).name;
            },
            filteredKassas() {
                let result = structuredClone(this.kassas);
                for (const withoutKassa of this.withoutKassas) {
                    result = result.filter(kassa => kassa.id != withoutKassa)
                }
                return result;
            }
        },
        methods: {
            handleChangeKassaSelection() {
                this.$emit("update:kassaParam", this.selectedKassa);
                this.$emit("change");
                this.kassaSelectionIsOpened = false;
            },
            openCloseKassaSelection() {
                this.kassaSelectionIsOpened = !this.kassaSelectionIsOpened;
            },
            addAutoCloseKassaSelection() {
                const kassaSelectionBlock = this.$refs.kassaSelectionBlock;
                document.body.addEventListener("click", (event) => {
                    if (kassaSelectionBlock.contains(event.target)) {
                        return
                    }
                    this.kassaSelectionIsOpened = false;
                })
            },
            autoResizeKassaSelection() {
                const kassaSelectionButton = this.$refs.kassaSelectionButton;
                const kassaSelection = this.$refs.kassaSelection;
                kassaSelection.style.width = `${kassaSelectionButton.getBoundingClientRect().width}px`;
            },
        },
        mounted() {
            this.addAutoCloseKassaSelection();
            this.autoResizeKassaSelection();
        }
    }
</script>