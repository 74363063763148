<template>
    <SelectBehaviorOfRemainigGoodComponent v-if="selectBehaviorOfRemainingGoodWindowIsOpened" @deleteGoodRemaining="deleteGoodRemaining" @moveGoodRemaining="moveGoodRemaining" @closeWindow="closeSelectBehaviorOfRemainingGoodWindow"/>
    <div>
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[35vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeUpdateGoodModalWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <div class="flex items-center justify-between mb-8">
                        <p v-if="updatingGood.format == 'good'" class="text-xl">Обновление товара</p>
                        <p v-else-if="updatingGood.format == 'service'" class="text-xl">Обновление услуги</p>
                        <p v-else-if="updatingGood.format == 'operation' && updatingGood.type == 'income'" class="text-xl">Обновление доходной операции</p>
                        <p v-else class="text-xl">Обновление расходной операции</p>
                    </div>
                    <div class="flex flex-col items-stretch gap-4">
                        <KassaMultiSelection ref="kassaSelectionBlock" v-model:multiSelectionParam="selectedKassas"/>
                        <TextInput ref="goodNameInput" v-model:textParam="goodName" labelContent="Наименование"/>
                        <TextInput ref="goodVerboseIdInput" @input="handleVerboseIdInput" v-model:textParam="goodVerboseId" labelContent="Артикул"/>
                        <PriceInput ref="addGoodPriceInput" v-model:priceParam="goodPrice" labelContent="Цена"/>
                        <div v-if="updatingGood.format != 'operation'" class="flex flex-col items-stretch gap-4">
                            <div v-for="(characteristicValue, characteristicName) in availableCharacteristics" :key="characteristicName">
                                <label :for="`add-good-characteristic-${characteristicName}`" class="mb-1 block text-sm text-gray-500">{{ characteristicName }}</label>
                                <select v-if="Array.isArray(characteristicValue)" v-model="goodCharacteristics[characteristicName]" :ref="`addGoodCharacteristic${characteristicName}Input`" :id="`add-good-characteristic-${characteristicName}`" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                                    <option :value="null"></option>
                                    <option v-for="value in characteristicValue" :key="value" :value="value">{{ value }}</option>
                                </select>
                                <TextInput v-else v-model:textParam="goodCharacteristics[characteristicName]"/>
                            </div>
                        </div>  
                        <div v-if="updatingGood.format == 'good' && updatingGood.amount">
                            <label class="mb-1 block text-sm text-gray-500">Количество</label>
                            <div class="grid grid-cols-5 gap-4 mb-4">
                                <TextInput textParam="Главный склад" :readOnly="true" class="col-span-3"/>
                                <PriceInput :priceParam="updatingGood.amount['main'] || 0" :readOnly="true" class="col-span-2"/>
                            </div>
                            <div v-for="kassa in selectedKassasAmounts" :key="kassa.id" class="grid grid-cols-5 gap-4 mb-4">
                                <TextInput :textParam="kassa.name" :readOnly="true" class="col-span-3"/>
                                <PriceInput :priceParam="updatingGood.amount[kassa.id] || 0" :readOnly="true" class="col-span-2"/>
                            </div>
                        </div>
                        <button v-if="hasChoiceToRemoveOrDeleteGoodRemaining" @click.stop="openSelectBehaviorOfRemainingGoodWindow" ref="sendRequestToUpdateGoodButton" type="button" class="mt-4 text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                        <button v-else @click.stop="sendRequestToUpdateGood" ref="sendRequestToUpdateGoodButton" :disabled="buttonIsDisabled" type="button" class="mt-4 text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import SelectBehaviorOfRemainigGoodComponent from '@/components/laptop/Good/SelectBehaviorOfRemainigGoodComponent.vue';
    import KassaMultiSelection from '@/components/InputComponents/KassaMultiSelection.vue';
    import TextInput from '@/components/InputComponents/TextInput.vue';
    import PriceInput from '@/components/InputComponents/PriceInput.vue';


    export default {
        name: "UpdateGoodModalWindowComponent",
        components: {
            SelectBehaviorOfRemainigGoodComponent,
            KassaMultiSelection,
            TextInput,
            PriceInput,
        },
        props: {
            updateGoodIsOpened: {
                type: Boolean,
                required: true,
            },
            updatingGood: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                selectBehaviorOfRemainingGoodWindowIsOpened: false,
                availableCharacteristics: {},

                goodFormat: null,
                goodType: null,
                goodName: null,
                goodVerboseId: null,
                goodPrice: null,
                goodCharacteristics: {},
                selectedKassas: {},
                deleteUnlinkedWarehouseGoods: false,

                savedSelectedKassas: {},
                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["companyData", "kassas"]),
            selectedKassasVerboseName() {
                let result = [];
                for (const [key, value] of Object.entries(this.selectedKassas)) {
                    if (value) {
                        result.push(this.kassas.find(kassa => kassa.id == key).name);
                    }
                }
                return result.join(" | ");
            },
            selectedKassasAmounts() {
                const result = [];
                for (const [key, value] of Object.entries(this.selectedKassas)) {
                    if (value) {
                        result.push(this.kassas.find(kassa => kassa.id == key));
                    }
                }
                return result;
            },
            hasChoiceToRemoveOrDeleteGoodRemaining() {
                const totalAmount = Object.values(this.updatingGood.amount).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                return this.updatingGood.format == 'good' && totalAmount > 0 && JSON.stringify(this.selectedKassas) != JSON.stringify(this.savedSelectedKassas)
            },
        },
        methods: {
            openSelectBehaviorOfRemainingGoodWindow() {
                if (!this.validateDataToUpdateGood()) {
                    return;
                }
                this.selectBehaviorOfRemainingGoodWindowIsOpened = true;
            },
            closeSelectBehaviorOfRemainingGoodWindow() {
                this.selectBehaviorOfRemainingGoodWindowIsOpened = false;
            },
            handleVerboseIdInput() {
                this.goodVerboseId = this.goodVerboseId.replace(/[^a-zA-Z0-9]/g, '').slice(0, 6);
            },
            validateDataToUpdateGood() {
                if (!this.goodName) {
                    const goodNameInput = this.$refs.goodNameInput.$refs.textInput;
                    goodNameInput.setCustomValidity("Наименование обязательное");
                    goodNameInput.reportValidity();
                    return false;
                }

                if (this.goodVerboseId && this.goodVerboseId.length != 6) {
                    const goodVerboseIdInput = this.$refs.goodVerboseIdInput.$refs.textInput;
                    goodVerboseIdInput.setCustomValidity("Артикул должен содержать ровно 6 символов");
                    goodVerboseIdInput.reportValidity();
                    return false;
                }

                if (Object.values(this.selectedKassas).every(value => value === false)) {
                    const kassaMultiSelectionComponent = this.$refs.kassaSelectionBlock;
                    const kassaSelectionBlock = kassaMultiSelectionComponent.$refs.kassaSelectionBlock;
                    kassaSelectionBlock.scrollIntoView({ behavior: "smooth", block: "start" });
                    kassaMultiSelectionComponent.isOpened = true;
                    return false;
                }

                return true;
            },
            async deleteGoodRemaining() {
                this.deleteUnlinkedWarehouseGoods = true;
                this.closeSelectBehaviorOfRemainingGoodWindow();
                await this.sendRequestToUpdateGood();
            },
            async moveGoodRemaining() {
                this.deleteUnlinkedWarehouseGoods = false;
                this.closeSelectBehaviorOfRemainingGoodWindow();
                await this.sendRequestToUpdateGood();
            },
            async sendRequestToUpdateGood() {
                if (!this.validateDataToUpdateGood()) {
                    return;
                }

                this.buttonIsDisabled = true;

                let goodType;
                if (this.goodFormat == "operation") {
                    goodType = this.goodType;
                } else {
                    goodType = "income";
                }

                let goodPrice;
                if (this.goodPrice) {
                    goodPrice = Number(this.goodPrice);
                } else {
                    goodPrice = 0;
                }

                const characteristics = {};
                for (const [characteristicName, characteristicValue] of Object.entries(this.goodCharacteristics)) {
                    if (characteristicValue != null) {
                        characteristics[characteristicName] = characteristicValue;
                    }
                }

                const payload = {
                    goodId: this.updatingGood.id, 
                    name: this.goodName, 
                    price: goodPrice, 
                    format: this.goodFormat, 
                    type: goodType, 
                    verbose_id: this.goodVerboseId, 
                    shops_data: this.selectedKassas, 
                    characteristics: characteristics, 
                    is_active: this.updatingGood.is_active,
                    delete_unlinked_warehouse_goods: this.deleteUnlinkedWarehouseGoods,
                    amount: this.updatingGood.amount,
                }

                const result = await this.$store.dispatch("updateGood", payload);
                if (result.successfully) {
                    this.$notify("Позиция успешно обновлена");
                    this.closeUpdateGoodModalWindow();
                } else {
                    if (result.error["__all__"][0] == "Нарушено ограничение \"model_good_unique_company_and_verbose_id\".") {
                        const goodVerboseIdInput = this.$refs.goodVerboseIdInput.$refs.textInput;
                        goodVerboseIdInput.setCustomValidity("Позиция с таким артикулом уже есть в вашем каталоге");
                        goodVerboseIdInput.reportValidity();
                    }
                }
                this.buttonIsDisabled = false;
            },
            closeUpdateGoodModalWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:updateGoodIsOpened", false);
            },
            initData() {
                for (const name in this.companyData.good_characteristics) {
                    this.availableCharacteristics[name] = null;
                }
                for (const name in this.updatingGood.characteristics) {
                    this.availableCharacteristics[name] = null;
                }
                
                for (const [name, value] of Object.entries(this.updatingGood.characteristics)) {
                    this.availableCharacteristics[name] = value;
                }
                for (const [name, value] of Object.entries(this.companyData.good_characteristics)) {
                    let newValue;
                    if (Array.isArray(value)) {
                        newValue = structuredClone(value);
                        if (name in this.updatingGood.characteristics && !value.includes(this.updatingGood.characteristics[name])) {
                            newValue.unshift(this.updatingGood.characteristics[name]);
                        }
                    } else {
                        newValue = value
                    }
                    this.availableCharacteristics[name] = newValue;
                }

                this.goodFormat = this.updatingGood.format;
                this.goodType = this.updatingGood.type;
                this.goodName = this.updatingGood.name,
                this.goodVerboseId = this.updatingGood.verbose_id;
                this.goodPrice = this.updatingGood.price;

                for (const name in this.companyData.good_characteristics) {
                    this.goodCharacteristics[name] = null;
                }
                for (const [name, value] of Object.entries(this.updatingGood.characteristics)) {
                    this.goodCharacteristics[name] = value;
                }

                for (const kassa of this.kassas) {
                    if (this.updatingGood.shop_ids.includes(Number(kassa.id))) {
                        this.selectedKassas[kassa.id] = true;    
                    } else {
                        this.selectedKassas[kassa.id] = false;
                    }
                }
                this.savedSelectedKassas = structuredClone(this.selectedKassas);
            },
        },
        created() {
            this.initData();
        },
        mounted() {
            
        },
    }
</script>