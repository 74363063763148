<template>
    <CatalogWindowComponent v-if="catalogWindowIsOpened" v-model:catalogWindowIsOpened="catalogWindowIsOpened" v-model:operations="addOperationOperations" :initialCatalogGoods="catalogGoods" :currentKassaId="kassaData.id" forWindow="operation"/>
    
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[60vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">Доход / Расход</p>
                    <div class="grid grid-cols-5 gap-4 mb-8">
                        <PaymentMethodSelection v-model:paymentMethodParam="addOperationTransactionMethod" labelContent="Метод" class="col-span-2"/>
                        <div class="cols-span-1"></div>
                        <OperationTypeSelection v-model:operationTypeParam="addOperationType" @change="handleChangingOperationType" labelContent="Тип транзакции" class="col-span-2"/>
                    </div>
                    <div class="w-full mb-8">
                        <div ref="addSaleSelectionOperationContainer" class="relative mb-2">
                            <div class="grid grid-cols-[repeat(24,minmax(0,1fr))] gap-3 mb-1">
                                <div class="col-span-9 text-sm text-gray-500">Операция</div>
                                <div class="col-span-4 text-sm text-gray-500">Количество</div>
                                <div class="col-span-5 text-sm text-gray-500">Цена</div>
                                <div class="col-span-6 text-sm text-gray-500">Сумма</div>
                            </div>
                            <div v-for="(addOperationOperation, index) in addOperationOperations" :key="addOperationOperation" class="mb-4">
                                <div class="grid grid-cols-[repeat(24,minmax(0,1fr))] gap-3 mb-2 relative">
                                    <div class="text-sm text-gray-500 flex items-center justify-center absolute top-1/2 -translate-y-1/2 left-[-2rem]">{{ index + 1 }}</div>
                                    <GoodSelection :ref="`goodSelection-${index}`" @change="selectGoodToAddOperationOperation(index)" @openCatalogWindow="openCatalogWindow" v-model:goodParam="addOperationOperation.good" :goodChoices="goodsToAddOperation" class="col-span-9"/>
                                    <AmountInput :ref="`operationAmountInput-${index}`" @input="updateTotalPrice(index)" v-model:amountParam="addOperationOperation.amount" :maxValue="addOperationOperation.maxAmount" class="col-span-4"/>
                                    <PriceInput :ref="`operationPriceInput-${index}`" @input="updateTotalPrice(index)" v-model:priceParam="addOperationOperation.price" :priceCurrency="addOperationCurrency" class="col-span-5"/>
                                    <PriceInput v-model:priceParam="addOperationOperation.total_price" :priceCurrency="addOperationCurrency" :readOnly="true" class="col-span-6"/>
                                    <div v-if="addOperationOperations.length > 1" class="absolute top-1/2 -translate-y-1/2 right-[-2rem] cursor-pointer flex items-center justify-end">
                                        <svg @click="deleteOperation(index)" class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z"/></svg>
                                    </div>
                                </div>
                                <span v-show="addOperationOperation.id" class="text-[15px] text-[#181818] opacity-[0.7]">{{ addOperationOperation.format != 'good' ? "В наличии: ∞": `В наличии: ${addOperationOperation.maxAmount}`}}</span>
                            </div>
                        </div>
                        <ButtonWithPlus @click="addOperationOperation" buttonContent="Добавить операцию"/>
                    </div>
                    <CommentInput v-model:commentParam="addOperationNote" labelContent="Комментарий" class="w-full mb-8"/>
                    <div class="w-full mb-8">
                        <div class="relative flex items-center">
                            <span class="mr-8">{{ $globalMethods.formatNumberWithSpaces(totalOfPricesToAddOperation) }}</span>
                            <select v-model="addOperationCurrency" name="add-operation-currency" id="add-operation-currency" class="ring-0 shadow-none border-0 outline-0 focus:ring-0 focus:shadow-none focus:border-0 focus:outline-0">
                                <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <button @click="createNewOperation" :disabled="buttonIsDisabled" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
            </div>
        </div>
    </div>
</template>


<script>
    import Decimal from 'decimal.js'; 
    import { mapState } from 'vuex';

    import CatalogWindowComponent from '@/components/laptop/Good/CatalogWindowComponent.vue';

    import OperationTypeSelection from '@/components/InputComponents/OperationTypeSelection.vue';
    import PaymentMethodSelection from '@/components/InputComponents/PaymentMethodSelection.vue';
    import GoodSelection from "@/components/InputComponents/GoodSelection.vue";
    import AmountInput from "@/components/InputComponents/AmountInput.vue";
    import PriceInput from "@/components/InputComponents/PriceInput.vue";
    import CommentInput from '@/components/InputComponents/CommentInput.vue';

    import ButtonWithPlus from '@/components/Button/ButtonWithPlus.vue';

    export default {
        name: "AddOperationWindowComponent",
        components: {
            CatalogWindowComponent, 
            OperationTypeSelection,
            PaymentMethodSelection,
            GoodSelection,
            AmountInput,
            PriceInput,
            CommentInput,
            ButtonWithPlus,
        },
        props: {
            addOperationWindowIsOpened: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                addOperationTransactionMethodIsOpened: false,
                catalogWindowIsOpened: false,

                catalogGoods: [],

                addOperationTransactionMethod: "",
                addOperationType: "income",
                addOperationOperations: [{amount: 1, price: 0, total_price: 0}],
                addOperationNote: "",
                addOperationCurrency: null,

                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["kassaData", "kassaGoods", "currencies", "labels"]),
            goodsToAddOperation() {
                let operationGoods = this.kassaGoods.filter(good => good.type == this.addOperationType && good.format == "operation");
                let usedGoods = this.addOperationOperations.map(operation => operation.good ? operation.good.id : undefined);
                let result = operationGoods.filter(good => !usedGoods.includes(good.id));
                return result;
            },
            totalOfPricesToAddOperation() {
                const result = this.addOperationOperations.reduce((acc, operation) => {
                    const total_price = String(operation.total_price || "0")
                    const price = new Decimal(total_price);
                    return acc.plus(price);
                }, new Decimal(0));

                return result.toFixed(2);
            },
        },
        methods: {
            validateNewOperationData() {
                for (const [index, operation] of this.addOperationOperations.entries()) {
                    if (!operation.id) {
                        const goodSelectionInput = this.$refs[`goodSelection-${index}`][0].$refs.goodSelectionInput;
                        goodSelectionInput.setCustomValidity("Поле товара обязательно для заполнения");
                        goodSelectionInput.reportValidity();
                        return false;
                    }
                    if (!operation.amount || operation.amount <= 0) {
                        const operationAmountInput = this.$refs[`operationAmountInput-${index}`][0].$refs.amountInput;
                        operationAmountInput.setCustomValidity("Поле количества товара обязательно для заполнения");
                        operationAmountInput.reportValidity();
                        return false;
                    }
                    if (operation.price <= 0) {
                        const operationPriceInput = this.$refs[`operationPriceInput-${index}`][0].$refs.priceInput;
                        operationPriceInput.setCustomValidity("Поле цены должно быть строго больше нуля");
                        operationPriceInput.reportValidity();
                        return false;
                    }
                }
                return true;
            },
            async createNewOperation() {
                if (!this.validateNewOperationData()) {
                    return
                }
                this.buttonIsDisabled = true;
                for (const operation of this.addOperationOperations) {
                    operation.amount = Number(operation.amount);
                    operation.price_without_discount = Number(operation.price);
                    operation.good_verbose_id = operation.goodVerboseId;
                }
                const result = await this.$store.dispatch("createNewOperation", {
                    addOperationTransactionMethod: this.addOperationTransactionMethod,
                    addOperationOperations: this.addOperationOperations,
                    addOperationNote: this.addOperationNote,
                    addOperationCurrency: this.addOperationCurrency,
                })
                if (result.successfully) {
                    this.closeWindow();
                    this.$notify("Операция успешно добавлена");
                }
                this.buttonIsDisabled = false;
            },
            addOperationOperation() {
                this.addOperationOperations.push({amount: 1, price: 0, total_price: 0});
            },
            deleteOperation(index) {
                this.addOperationOperations.splice(index, 1);
            },
            updateTotalPrice(index) {
                const operation = this.addOperationOperations[index];
                if (operation.price && operation.amount) {
                    operation.total_price = Number(new Decimal(operation.price).mul(operation.amount).toString());
                } else {
                    operation.total_price = 0;
                }
            },
            selectGoodToAddOperationOperation(index) {
                const operation = this.addOperationOperations[index];
                const good = operation.good;
                if (!good) {
                    return;
                }
                operation.goodVerboseId = good.verbose_id;
                operation.id = good.id;
                operation.name = good.name;
                operation.price = Number(good.price);
                operation.amount = 1;
                operation.total_price = Number(new Decimal(operation.price).mul(operation.amount).toString());                
            },
            openCatalogWindow(typeOfCatalog) {
                this.typeOfCatalog = typeOfCatalog;
                this.catalogGoods = this.kassaGoods.filter(good => good.type == this.addOperationType && good.format == "operation");
                this.catalogWindowIsOpened = true;
            },
            handleChangingOperationType() {
                this.addOperationOperations = [{ amount: 1, price: 0, total_price: 0}];
            },
            closeWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:addOperationWindowIsOpened", false);
            },
            openWindow() {
                this.addOperationTransactionMethod = this.kassaData.default_payment;
                this.addOperationCurrency = this.kassaData.default_currency;
            },
        },
        created() {
            this.openWindow();
        },
        mounted() {

        },
    }
</script>