<template>
    <div>
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
        <div class="relative">
            <input v-model="param" @input="handleInput" ref="passwordInput" :readonly="readOnly" :disabled="readOnly" :id="uniqueId" :type="passwordIsShowen ? 'text' : 'password'" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4 pr-9">
            <svg v-if="passwordIsShowen" @click="hidePassword" class="absolute right-2 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
            <svg v-else @click="showPassword" class="absolute right-2 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
        </div>
    </div>
</template>


<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "PasswordInput",
        props: {
            passwordParam: {
                type: [String, Number, null],
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            param: {
                get() {
                    return this.passwordParam;
                },
                set(value) {
                    if (this.readOnly) {
                        return;
                    }
                    this.$emit("update:passwordParam", value);
                }
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
                passwordIsShowen: false,
            }
        },
        methods: {
            handleInput() {
                this.$refs.passwordInput.setCustomValidity("");
                this.$emit("input");
            },
            showPassword() {
                this.passwordIsShowen = true;
            },
            hidePassword() {
                this.passwordIsShowen = false;
            }
        }
    }
</script>