<template>
    <div class="">
        <SelectModalWindow v-if="selectRecalculationClientDiscountIsOpened" @confirm1="saveNewCompanyData(true)" @confirm2="saveNewCompanyData(false)" v-model:isOpened="selectRecalculationClientDiscountIsOpened" :buttonIsDisabled="buttonIsDisabled" option1="Пересчитать скидки" option2="Оставить текущие скидки" title="К главной валюте компании прикреплена политика скидок. Хотите ли вы пересчитать скидки для ваших клиентов в соответствии с новой главной валютой компании?"/>
        <section class="mx-8 grid grid-cols-2 gap-[10px] text-sm">
            <div class="px-8 py-6 mb-8 bg-white rounded-md">
                <div class="flex items-center justify-between mb-8">
                    <p class="text-2xl py-[2px]">Персональные данные</p>
                    <button @click="saveNewProfileData" v-if="showSaveButtonToProfileData" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                        <span>Сохранить</span>
                    </button>
                </div>
                <div class="flex flex-col gap-6">
                    <div class="flex items-end justify-between gap-4">
                        <TextInput v-model:textParam="name" labelContent="Имя" class="w-[80%]"/>
                    </div>
                    <div class="flex items-end justify-between gap-4">
                        <TextInput v-model:textParam="phone" labelContent="Телефон" :readOnly="true" class="w-[80%]"/>
                        <button @click="sendRequestToChangePhone" class="text-blue-600 p-2">Изменить</button>
                    </div>
                    <div class="flex items-end justify-between gap-4">
                        <TextInput v-model:textParam="email" labelContent="Email" :readOnly="true" class="w-[80%]"/>
                        <button @click="sendRequestToChangeEmail" class="text-blue-600 p-2">Изменить</button>
                    </div>
                    <div class="flex items-end justify-between gap-4">
                        <TextInput v-model:textParam="token" labelContent="Личный токен" :readOnly="true" class="w-[80%]"/>
                        <button @click="copyToken" class="text-blue-600 p-2">Копировать</button>
                    </div>
                </div>
            </div>
            <div class="px-8 py-6 mb-8 bg-white rounded-md">
                <div class="flex items-center justify-between mb-8">
                    <p class="text-2xl py-[2px]">Данные компании</p>
                    <button @click="openSelectRecalculationClientDiscount" v-if="showSaveButtonToCompanyData" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                        <span>Сохранить</span>
                    </button>
                </div>
                <div class="flex flex-col gap-6">
                    <div class="flex items-end justify-between gap-4">
                        <TextInput v-model:textParam="companyName" labelContent="Наименование" class="w-[80%]"/>
                    </div>
                    <div class="flex items-end justify-between gap-4">
                        <TextInput v-model:textParam="officialCompanyName" labelContent="Официальное наименование" class="w-[80%]"/>
                    </div>
                    <div class="flex items-end justify-between gap-4">
                        <div class="w-[80%]">
                            <label for="main-currency" class="mb-1 block text-sm text-gray-500">Основная валюта</label>
                            <select v-model="mainCurrency" id="main-currency" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                                <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex items-end justify-between gap-4">
                        <TextInput v-model:textParam="plan" labelContent="Тариф" :readOnly="true" class="w-[80%]"/>
                        <button class="text-blue-600 p-2">Изменить</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import TextInput from '@/components/InputComponents/TextInput.vue';

    import SelectModalWindow from '@/components/modal/SelectModalWindow.vue';

    export default {
        name: "PersonalAccount",
        components: {
            TextInput,

            SelectModalWindow,
        },
        computed: {
            ...mapState(["profileData", "companyData", "currencies"]),
            showSaveButtonToProfileData() {
                return this.name != this.profileData.name;
            },
            showSaveButtonToCompanyData() {
                return this.companyName != this.companyData.name || this.officialCompanyName != this.companyData.official_name || this.mainCurrency != this.companyData.main_currency
            },
        },
        data() {
            return {
                verbosePlan: {
                    free: "Бесплатный",
                    standard: "Стандартный",
                    custom: "Гибкий",
                },
                verbosePlanPeriod: {
                    monthly: "мес",
                    annually: "год",
                },
                
                name: null,
                phone: null,
                email: null,
                token: null,

                companyName: null,
                officialCompanyName: null,
                mainCurrency: null,
                plan: null,

                buttonIsDisabled: false,
                selectRecalculationClientDiscountIsOpened: false,
            }
        },
        methods: {
            initData() {
                this.name = this.profileData.name;
                this.phone = this.profileData.phone;
                this.email = this.profileData.email;
                this.token = this.profileData.personal_token;
                this.companyName = this.companyData.name;
                this.officialCompanyName = this.companyData.official_name;
                this.mainCurrency = this.companyData.main_currency;
                this.plan = this.getVerbosePlan();
            },
            getVerbosePlan() {
                const plan = this.companyData.plan;

                let planName = `${this.verbosePlan[plan.name]} (${plan.price}₽ / ${this.verbosePlanPeriod[plan.period]})`;
                let dateOfEnd = this.$globalMethods.formatDate("ru", plan.date_of_end);

                let paymentIsActive;
                if (plan.payment_is_active) {
                    paymentIsActive = "Платежи активны";
                } else {
                    paymentIsActive = "Платежи остановлены";
                }

                let result = `${planName} | Действует до ${dateOfEnd} | ${paymentIsActive}`;
                return result;
            },
            async saveNewProfileData() {
                const result = await this.$store.dispatch("updateProfileData", this.name);
                if (result.successfully == true) {
                    this.showSaveButtonToProfileData = false;
                }
            },
            async openSelectRecalculationClientDiscount() {
                if (this.mainCurrency != this.companyData.main_currency) {
                    this.selectRecalculationClientDiscountIsOpened = true;
                } else {
                    await this.saveNewCompanyData(false);
                }
            },
            async saveNewCompanyData(recalculateClientDiscount) {
                this.buttonIsDisabled = true;

                let result = await this.$store.dispatch("updateCompanyData", { name: this.companyName, official_name: this.officialCompanyName, main_currency: this.mainCurrency, recalculate_client_discount: recalculateClientDiscount });
                if (result.successfully) {
                    if (recalculateClientDiscount) {
                        result = await this.$store.dispatch("fetchClients");
                        if (!result.successfully) {
                            return
                        }
                    }
                    this.showSaveButtonToCompanyData = false;
                    this.selectRecalculationClientDiscountIsOpened = false;
                }

                this.buttonIsDisabled = false;
            },
            copyToken() {
                navigator.clipboard.writeText(this.token)
                .then(() => {
                    this.$notify("Токен был успешно скопирован");
                })
                .catch(() => {
                    this.$notify("Не удалось скопировать токен...", "red");
                });
            },
            async sendRequestToChangePhone() {
                await this.$store.dispatch("changePhoneStart");
            },
            async sendRequestToChangeEmail() {
                await this.$store.dispatch("changeEmailStart");
            }
        },
        mounted() {
            this.initData();
        }
    }
</script>