<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[35vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeClientDetailInfoWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">Информация о клиенте</p>
                    <div class="flex flex-col items-stretch gap-4">
                        <TextInput ref="nameInput" v-model:textParam="name" labelContent="ФИО"/>
                        <PhoneInputComponent ref="phoneInput" v-model:inputParam="phone" labelContent="Телефон"/>
                        <TextInput ref="emailInput" v-model:textParam="email" labelContent="Email"/>
                        <div>
                            <DiscountInput v-model:discountParam="discount" labelContent="Персональная скидка" class="mb-2"/>
                            <CheckboxInput v-model:checkboxParam="useDiscountPolicy" labelContent="Действует общая политика скидок"/>
                        </div>
                        <CommentInput v-model:commentParam="note" labelContent="Комментарий"/>
                        <div>
                            <label for="client-total-payment" class="mb-1 block text-sm text-gray-500">Сумма покупок</label>
                            <div v-for="currency in Object.keys(totalPayments)" :key="currency" class="grid grid-cols-10 mb-2">
                                <PriceInput ref="addClientTotalPaymentsInput" v-model:priceParam="totalPayments[currency]" :priceCurrency="currency" class="col-span-8 mr-2"/>
                                <div class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4 col-span-2 text-sm rounded">
                                    <span>{{ currency }}</span>
                                </div>
                            </div>
                        </div>
                        <button @click="updateClientInfo" :disabled="buttonIsDisabled" type="button" class="mt-4 text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import PhoneInputComponent from '@/components/InputComponents/PhoneInputComponent.vue';
    import CommentInput from '@/components/InputComponents/CommentInput.vue';
    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';
    import TextInput from '@/components/InputComponents/TextInput.vue';
    import DiscountInput from '@/components/InputComponents/DiscountInput.vue';
    import PriceInput from '@/components/InputComponents/PriceInput.vue';

    export default {
        name: "ClientDetailInfoWindow",
        components: {
            PhoneInputComponent,
            CommentInput,
            CheckboxInput,
            TextInput,
            DiscountInput,
            PriceInput,
        },
        props: {
            isOpened: {
                type: Boolean,
                required: true,
            },
            client: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                clientId: null,
                name: null,
                phone: null,
                email: null,
                discount: null,
                useDiscountPolicy: null,
                note: null,
                totalPayments: null,

                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["currencies"])
        },
        methods: {
            validateDataToUpdate() {
                if (this.name.length == 0) {
                    const nameInput = this.$refs.nameInput.$refs.textInput;
                    nameInput.setCustomValidity("Поле имени обязательное");
                    nameInput.reportValidity();
                    return false;
                }
                if (this.phone.length == 0) {
                    const phoneInput = this.$refs.phoneInput.$refs.phoneInput;
                    phoneInput.setCustomValidity("Поле телефона обязательное");
                    phoneInput.reportValidity();
                    return false;
                }
                if (!this.$globalMethods.validatePhone(this.phone)) {
                    const phoneInput = this.$refs.phoneInput.$refs.phoneInput;
                    phoneInput.setCustomValidity("Введите корректный номер телефона");
                    phoneInput.reportValidity();
                    return false;
                }

                const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;
                if (!emailRegex.test(this.email)) {
                    const emailInput = this.$refs.emailInput.$refs.textInput;
                    emailInput.setCustomValidity("Значение должно быть корректным email");
                    emailInput.reportValidity();
                    return false;
                }

                if (!this.discount) {
                    this.discount = 0;
                } else {
                    this.discount = Number(this.discount);
                }

                for (const currency in this.totalPayments) {
                    this.totalPayments[currency] = Number(String(this.totalPayments[currency]).replace(/\s/g, ''));
                }

                return true;
            },
            async updateClientInfo() {
                if (!this.validateDataToUpdate()) {
                    return
                }

                this.buttonIsDisabled = true;

                const result = await this.$store.dispatch("updateClient", {
                    clientId: this.clientId, 
                    email: this.email, 
                    phone: this.phone, 
                    name: this.name, 
                    note: this.note || "", 
                    discount: Number(this.discount), 
                    use_discount_policy: this.useDiscountPolicy, 
                    total_payments: this.totalPayments,
                })

                if (result.successfully) {
                    this.$notify("Клиент успешно обновлен");
                    this.closeClientDetailInfoWindow();
                } else {
                    if (result.error["__all__"][0] == "Нарушено ограничение \"model_client_unique_company_and_phone\".") {
                        const phoneInput = this.$refs.phoneInput.$refs.phoneInput;
                        phoneInput.setCustomValidity("Клиент с таким номером телефона уже есть в вашей базе");
                        phoneInput.reportValidity();
                    } else if (result.error["__all__"][0] == "Нарушено ограничение \"model_client_unique_company_and_email\".") {
                        const emailInput = this.$refs.emailInput.$refs.textInput;
                        emailInput.setCustomValidity("Клиент с таким email уже есть в вашей базе");
                        emailInput.reportValidity();
                    }
                }

                this.buttonIsDisabled = false;
            },
            closeClientDetailInfoWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:isOpened", false);
                this.$emit("update:client", null);
            },
            copyClientInfo() {
                const {id, name, phone, email, discount, use_discount_policy, note, total_payments} = structuredClone(this.client);
                this.clientId = id;
                this.name = name;
                this.phone = phone;
                this.email = email;
                this.discount = discount;
                this.useDiscountPolicy = use_discount_policy;
                this.note = note;
                this.totalPayments = total_payments;
                for (const currency in this.totalPayments) {
                    this.totalPayments[currency] = this.$globalMethods.formatNumberWithSpaces(this.totalPayments[currency]);
                }
            },
        },
        created() {
            this.copyClientInfo();
        }
    }
</script>