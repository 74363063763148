import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import './app.css'
import 'quill/dist/quill.snow.css';
import globalMethods from './globalMethods.js';
import notification from './plugins/notification'


const app = createApp(App);
app.config.globalProperties.$globalMethods = globalMethods;
app.use(store).use(router).use(notification, store)
// .use(VueReCaptcha, { siteKey: '6LfyylQpAAAAAEaryNEqtXohIOMPhN8KHT2qKMM5' })
.mount('#app');
