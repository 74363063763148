<template>
    <div>
        <SupplyingOrMovingDetailWindow v-if="supplyingOrMovingDetailWindowIsOpened" v-model:transferDetailWindowIsOpened="supplyingOrMovingDetailWindowIsOpened" :transfer="currentTranfer"/>
        <AddMovingModalWindow v-if="addMovingModalWindowIsOpened" v-model:addMovingModalWindowIsOpened="addMovingModalWindowIsOpened"/>
        <AddSupplyingModalWindow v-if="addSupplyingModalWindowIsOpened" v-model:addSupplyingModalWindowIsOpened="addSupplyingModalWindowIsOpened"/>

        <section class="mx-8 px-8 py-6 mb-[10px] bg-white rounded-md flex justify-between items-start">
            <div class="flex justify-between w-full">
                <div class="flex items-center gap-8">
                    <button type="button" @click="goToGoodList" class="rounded-md flex items-center">Каталог</button>
                    <button type="button" @click="goToCharacteristicSettings" class="rounded-md flex items-center">Характеристики</button>
                    <button type="button" class="rounded-md bg-indigo-100 px-4 py-2 shadow">Поставки</button>
                </div>
                <div class="flex items-stretch gap-4">
                    <input v-model="searchValue" type="date" class="border rounded-lg border-gray-400 text-gray-400 mr-8">
                    <button @click="openAddSupplyingModalWindow" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                        <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M438-274h86v-162h162v-86H524v-164h-86v164H274v86h164v162Zm42.07 206q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Z"/></svg>
                        <span>Поставка</span>
                    </button>
                    <button @click="openAddMovingModalWindow" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                        <span>Перемещение/Списание</span>
                    </button>
                </div>
            </div>
        </section>

        <section class="mx-8 mb-8">
            <div class="relative overflow-x-auto shadow-md sm:rounded-md">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead class="text-sm text-gray-700 bg-white">
                        <tr class="border-b border-gray-300">
                            <th scope="col" class="px-4 py-3">
                                <span class="font-normal">Дата</span>
                            </th>
                            <th scope="col" class="px-4 py-3">
                                <span class="font-normal">Тип</span>
                            </th>
                            <th scope="col" class="px-4 py-3">
                                <span class="font-normal">Откуда</span>
                            </th>
                            <th scope="col" class="px-4 py-3">
                                <span class="font-normal">Куда</span>
                            </th>
                            <th scope="col" class="px-4 py-3 w-[100%]">
                                <span class="font-normal">Комментарий</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-sm">
                        <tr v-if="searchedSupplyingAndMovingRecords.length == 0 && searchValue">
                            <td colspan="10" class="text-center text-lg p-2">У вас нет поставок, перемещений или списаний за выбраную дату</td>
                        </tr>
                        <tr v-else-if="searchedSupplyingAndMovingRecords.length == 0">
                            <td colspan="10" class="text-center text-lg p-2">У вас пока нет поставок, перемещений или списаний</td>
                        </tr>
                        <tr @click="openSupplyingOrMovingDetailWindow(record)" v-for="record in searchedSupplyingAndMovingRecords" :key="record.id" class="font-normal text-black bg-white border-b cursor-pointer hover:bg-gray-50">
                            <td class="px-4 py-2 whitespace-nowrap">{{ $globalMethods.formatDateTime(record.date_of_created) }}</td>
                            <td v-if="record.warehouse" class="px-4 py-2 whitespace-nowrap">Поставка</td>
                            <td v-else-if="record.is_write_off" class="px-4 py-2 whitespace-nowrap">Списание</td>
                            <td v-else class="px-4 py-2 whitespace-nowrap">Перемещение</td>
                            <td class="px-4 py-2 whitespace-nowrap">{{ getVerboseWarehouseName(record.warehouse_from) }}</td>
                            <td class="px-4 py-2 whitespace-nowrap">{{ getVerboseWarehouseName(record.warehouse_to || record.warehouse) }}</td>
                            <td class="px-4 py-2">{{ record.description }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import AddMovingModalWindow from '@/components/laptop/Good/AddMovingModalWindow.vue';
    import SupplyingOrMovingDetailWindow from '@/components/laptop/Good/SupplyingOrMovingDetailWindow.vue';
    import AddSupplyingModalWindow from '@/components/laptop/Good/AddSupplyingModalWindow.vue';

    export default {
        name: "SupplyingAndMovingWindow",
        components: {
            AddMovingModalWindow,
            SupplyingOrMovingDetailWindow,
            AddSupplyingModalWindow,
        },
        props: {
            mode: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                addSupplyingModalWindowIsOpened: false,
                addMovingModalWindowIsOpened: false,
                supplyingOrMovingDetailWindowIsOpened: false,

                searchValue: "",
                currentTranfer: null,
            }
        },
        computed: {
            ...mapState(["supplyingsAndMovings", "kassas"]),
            searchedSupplyingAndMovingRecords() {
                let result = structuredClone(this.supplyingsAndMovings);
                if (this.searchValue) {
                    result = result.filter(record => this.$globalMethods.dropTimezoneFromDateTime(record.date_of_created).includes(this.searchValue))
                }
                result.sort((record1, record2) => new Date(record2.date_of_created) - new Date(record1.date_of_created));
                return result
            },
        },
        methods: {
            openSupplyingOrMovingDetailWindow(transfer) {
                document.body.classList.add("overflow-hidden");
                this.currentTranfer = transfer;
                this.supplyingOrMovingDetailWindowIsOpened = true;
            },
            openAddMovingModalWindow() {
                document.body.classList.add("overflow-hidden");
                this.addMovingModalWindowIsOpened = true;
            },
            openAddSupplyingModalWindow() {
                document.body.classList.add("overflow-hidden");
                this.addSupplyingModalWindowIsOpened = true;
            },
            getVerboseWarehouseName(warehouseName) {
                if (!warehouseName) {
                    return;
                }
                if (warehouseName == "main") {
                    return "Главный склад"
                }
                const kassa = this.kassas.find(kassa => kassa.id == warehouseName)
                if (!kassa) {
                    return "*Удаленный склад";
                }

                return kassa.name;
            }, 
            goToGoodList() {
                this.$emit("update:mode", "goods");
            },
            goToCharacteristicSettings() {
                this.$emit("update:mode", "characteristics");
            },
        }
    }
</script>