<template>
    <div class="min-w-screen min-h-screen flex items-center justify-center">
        <div class="w-[500px] h-auto bg-white rounded-lg px-[4rem] py-[3rem]">
            <div v-if="mode == 'sign-in'">
                <form @submit.prevent="sendRequestToSignIn">
                    <p class="text-2xl mb-8">Войти в систему</p>
                    <TextInput v-model:textParam="username" labelContent="Email" ref="usernameInput" class="mb-4"/>
                    <PasswordInput v-model:passwordParam="password" labelContent="Пароль" ref="passwordInput" class="mb-2"/>
                    <p @click="goToResetPasswordMode" class="text-sm text-blue-600 cursor-pointer text-right mb-8">Забыл пароль</p>
                    <p v-if="errorMessage" class="text-red-500 text-center mb-4">{{ errorMessage }}</p>
                    <button type="submit" class="w-full bg-custom-blue hover:bg-custom-blue-hover text-center text-white font-medium rounded-lg text-sm px-5 py-2 mb-8">Войти</button>
                    <div class="flex items-center flex-col gap-1 text-sm">
                        <p>Еще не зарегистированы?</p>
                        <router-link :to="{'name': 'sign-up'}" class="text-blue-600 cursor-pointer">Создать аккаунт</router-link>
                    </div>
                </form>
            </div>
            <div v-else>
                <p @click="goToSignInMode" class="cursor-pointer text-indigo-600 mb-4 flex items-center justify-start gap-2">
                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg>
                    назад
                </p>
                <form @submit.prevent="sendRequestToResetPassword">
                    <p class="text-2xl mb-8">Запрос на изменение пароля</p>
                    <TextInput v-model:textParam="username" labelContent="Email" ref="usernameInput" class="mb-8"/>
                    <button type="submit" class="w-full bg-custom-blue hover:bg-custom-blue-hover text-center text-white font-medium rounded-lg text-sm px-5 py-2 mb-8">Сбросить пароль</button>
                    <div class="flex items-center flex-col gap-1 text-sm">
                        <p>Еще не зарегистированы?</p>
                        <router-link :to="{'name': 'sign-up'}" class="text-blue-600 cursor-pointer">Создать аккаунт</router-link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
    import TextInput from '@/components/InputComponents/TextInput.vue';
    import PasswordInput from '@/components/InputComponents/PasswordInput.vue';

    export default {
        name: "SignIn",
        components: {
            TextInput,
            PasswordInput,
        },
        computed: {

        },
        data() {
            return {
                mode: "sign-in",
                username: null,
                password: null,
                errorMessage: null,
            }
        },
        methods: {
            goToSignInMode() {
                this.errorMessage = "";
                this.mode = 'sign-in';
            },
            goToResetPasswordMode() {
                this.errorMessage = "";
                this.mode = 'reset-password';
            },
            validateDataToResetPassword() {
                if (!this.username) {
                    const usernameInput = this.$refs.usernameInput.$refs.textInput;
                    usernameInput.setCustomValidity("Введите email для сброса пароля")
                    usernameInput.reportValidity();
                    return false;
                }
            },
            validateData() {
                if (!this.username) {
                    const usernameInput = this.$refs.usernameInput.$refs.textInput;
                    usernameInput.setCustomValidity("Введите ваш email")
                    usernameInput.reportValidity();
                    return false;
                }
                if (!this.password) {
                    const passwordInput = this.$refs.passwordInput.$refs.passwordInput;
                    passwordInput.setCustomValidity("Введите ваш пароль")
                    passwordInput.reportValidity();
                    return false;
                }
                return true;
            },
            async sendRequestToSignIn() {
                this.errorMessage = "";

                if (!this.validateData()) {
                    return;
                }

                const data = {
                    username: this.username,
                    password: this.password
                }
                const result = await this.$store.dispatch('fetchSignIn', data);
                if (!result.successfully) {
                    if (result.error == "invalid credentials") {
                        this.errorMessage = "Неверный данные для входа";
                    } else if (result.error == "the user is not confirmed") {
                        this.errorMessage = "Cперва подтвердите ваш email, перейдя по ссылке в отправленном письме";
                    } else if (result.error == "the user deleted") {
                        this.errorMessage = "Пользователь был удален из системы";
                    } else {
                        this.errorMessage = "Неожиданная ошибка. Попробуйте снова немного позже или обратитесь в поддержку";
                    }
                }
            },
            async sendRequestToResetPassword() {
                this.errorMessage = "";

                if (!this.validateDataToResetPassword()) {
                    return;
                }

                const data = {username: this.username};
                const result = await this.$store.dispatch("sendRequestToResetPassword", data);
                if (!result.successfully) {
                    this.errorMessage = "Пользователя с указанным email не существует";
                }
            },
        },
    }
</script>